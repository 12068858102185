import React, {
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {
  catatLogDokter,
  empty,
  tglDDMMMYYYY,
  toCurrency,
  triggerEventBus,
} from 'utils/functions'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {VmCalendarDaysIcon, VmMagnifyingGlassCircleIcon, VmMagnifyingGlassIcon, VmPlusCircleIcon, VmTrashIcon, VmXCircleIcon} from 'components/icons/solid'
import {VmButton, VmSpinner, VmText, VmView, VmInput, VmModal} from 'components'
import {ItemCard, Items, defaultClassNames} from 'template/Form/HandlerForm'
import {PrdUbahProfil, TemplateForm} from 'template'
import SearchContent from 'template/Modal/SearchContent'
import classNames from 'classnames'
import SearchFaskesRujukan from './SearchFaskesRujukan'
import useAddEventListener from 'utils/useAddEventListener'
import {isArray, getKeyByValue} from 'utils'
import {isFunction, isNode} from 'utils/typeof'
import SearchObat from './SearchObat'
import {useCarts} from 'pages/prd-penjualan-obat-resep/utils'
import CardItem from './CardItem'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {getElement} from 'utils/get-element'
import { mDeleteObatPcare } from './api'

registerLocale('id', id)
const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_UTAMA: API5,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
  REACT_APP_BASE_URL_BPJS: APIBPJS,
} = process.env

const LisItems = ({
  data = [],
  onPick = () => {},
  selected = null,
  valuesearch,
}) => {
  return data?.map((im, idx) => {
    // console.log(data, 'listItems')
    const key = `bpjs-${idx}.${Date.now()}`,
      active =
        JSON.stringify({im: selected}) ===
        JSON.stringify({im: im[valuesearch.replace('nm', 'kd')]})
    return (
      <VmView
        key={key}
        onClick={() => (active ? onPick() : onPick(im))}
        className={classNames(
          'cursor-pointer',
          active ? 'border-2 border-sky-500 bg-sky-100' : '',
          'p-3 border rounded-2xl'
        )}
      >
        <VmView>
          {/* {`${im[valuesearch.replace('nm', 'kd')]} - ${im[valuesearch]}` || '-'} */}
          {valuesearch.replace('nm', '').toLowerCase() != 'alasantacc'
            ? `${im[valuesearch.replace('nm', 'kd')]} - ${im[valuesearch]}` ||
              '-'
            : `${im[valuesearch]}`}
        </VmView>
      </VmView>
    )
  })
}

const originalLog = console.log;  
console.log = function(...args) {
  const allowedLogs = ['Log:'];
  if (allowedLogs.some(log => args.includes(log))) {
    originalLog.apply(console, args);
  }
};

const [containerClassName, titleClassName, classItem, classSparator] =
  defaultClassNames

export default function PcareExamination({
  uid = null,
  pid = null,
  kid = null,
  rmid = null,
  mode = 'web', // web | phone
  pcareVisible = false,
  rdata = {},
  kdata = {},
  onChangeData = () => ({}),
  optcarts = {
    name: 'PcareExamntnCarts',
    flags: 'kdObat',
    flagstok: 'sedia',
  },
  fieldkey = ['kdObat'],
  disabled = false,
  headmcu={}
}) {
  // console.log(rdata, 'rdata_')
  // console.log(kdata, 'kdata_')
  // console.log(uid, 'uid_')
  const showlog = false
  const fileInputRef = useRef(null)
  const tFormRef = createRef()
  const modalRef = createRef()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const initialApiParams = {
    a: 'dk13',
    reg: 'dbdok',
    uid,
    pid,
    kid,
    rmid,
    date: moment().format('YYYY-MM-DD'),
  }

  const handleModal = (e, position = null) => {
    if (position) {
      setmodalposition(position)
    }
    if (!!e) {
      if (![isArray(e), isNode(e), isFunction(e)].includes(true)) {
        modalRef.current.close()
        setmodalposition(null)
      } else {
        modalRef.current.open(e)
      }
    } else {
      if (!!modalRef?.current?.isOpen) {
        modalRef.current.close()
      }
    }
  }

  const onCloseModal = () => {
    const event = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      which: 27,
      bubbles: true,
    })

    document.dispatchEvent(event)
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const closeAlert = () => setpropsalertform(defaultpropsalertform)

  const onDelete = (item, onCancel = closeAlert) => {
    return setpropsalertform({
      ...propsalertform,
      type: 2,
      visible: true,
      title: 'Hapus Item',
      children: (
        <>
          Apakah anda akan menghapus obat <b>{item?.nmObat}</b>?
        </>
      ),
      onConfirm: () => mDeleteObatPcare({
        input: {
          uid: uid,
          data: {
            kdObatSK: item?.kdObatSK,
            noKunjungan: rdata?.kunpcarekunjungan || '',
          },
        },
      }).then(({data: resdata = {}}) => {
        removetocarts(item)
      }),
      onClose: closeAlert,
      onCancel: onCancel,
    })
  }

  const onClickData = (e) => {
    // console.log(e, 'onClickData');
    const {ariaLabel} = e.target
    if(ariaLabel == 'view-diagnosa3' || (e?.target?.nearestViewportElement?.ariaLabel == 'view-diagnosa3')){
      var viewdiagnosa3 = document.getElementById("diagnosa3");
      if (viewdiagnosa3.style.display == "none") {
        viewdiagnosa3.style.display = "block"
      }
      return
    }

    if(ariaLabel == 'unselect-diagnosa3' || (e?.target?.nearestViewportElement?.ariaLabel == 'unselect-diagnosa3')){
      var viewdiagnosa3 = document.getElementById("diagnosa3");
      setDataPcare({
        ...dataPcare,
        kddiag3:'',
      })
      setdiagnosa3('')
      onChangeData({
        diagnosa3: '',
        kddiag3: '',
      })
      viewdiagnosa3.style.display = "none"
      return
    }

    if(ariaLabel == 'unselect-alergimakanan' || (e?.target?.nearestViewportElement?.ariaLabel == 'unselect-alergimakanan')){
      setDataPcare({
        ...dataPcare,
        kdalergimakanan:'',
      })
      setalergimakanan('')
      onChangeData({
        kdalergimakanan: '',
      })
      return
    }

    if(ariaLabel == 'unselect-alergiudara' || (e?.target?.nearestViewportElement?.ariaLabel == 'unselect-alergiudara')){
      setDataPcare({
        ...dataPcare,
        kdalergiudara:'',
      })
      setalergiudara('')
      onChangeData({
        kdalergiudara: '',
      })
      return
    }

    if(ariaLabel == 'unselect-alergiobat' || (e?.target?.nearestViewportElement?.ariaLabel == 'unselect-alergiobat')){
      setDataPcare({
        ...dataPcare,
        kdalergiobat:'',
      })
      setalergiobat('')
      onChangeData({
        kdalergiobat: '',
      })
      return
    }

    if(ariaLabel == 'unselect-prognosa' || (e?.target?.nearestViewportElement?.ariaLabel == 'unselect-prognosa')){
      setDataPcare({
        ...dataPcare,
        kdprognosa:'',
      })
      setprognosa('')
      onChangeData({
        kdprognosa: '',
      })
      return
    }
    // console.log(e, 'eee')
    // console.log(ariaLabel, 'ariaLabel')
    var actialfield = ''
    // newvalue = null,
    // {handleModal, modalRef} = formRef.current
    // console.log(data, 'onClickData')

    var jenisFaskes = 'spesialis'
    var kdsubspesialis = subspesialis?.id || ''
    var kdkhusus = khusus?.id || ''
    var kdsarana = sarana?.id || ''
    var noKartu = rdata?.pasnobpjs
    var tglEstRujuk = moment(tglberkunjungpcare).format('DD-MM-YYYY')

    if (jenisRujukan == 1) {
      jenisFaskes = 'khusus'
    }

    if (kdata?.pcaredata?.peserta?.noKartu) {
      noKartu = kdata?.pcaredata?.peserta?.noKartu
    }

    if (`${ariaLabel}`.includes('select-faskes') && mode == 'web') {
      var jenisFaskes = 'spesialis'
      var kdsubspesialis = subspesialis?.id || ''
      var kdkhusus = khusus?.id || ''
      var kdsarana = sarana?.id || ''
      var noKartu = rdata?.pasnobpjs
      var tglEstRujuk = moment(tglberkunjungpcare).format('DD-MM-YYYY')

      // console.log(tglEstRujuk, 'tglEstRujuk');

      if (jenisRujukan == 1) {
        jenisFaskes = 'khusus'
      }

      if (kdata?.pcaredata?.peserta?.noKartu) {
        noKartu = kdata?.pcaredata?.peserta?.noKartu
      }

      const propsmodal = {
        uid,
        jenisFaskes,
        kdsubspesialis,
        kdkhusus,
        kdsarana,
        noKartu,
        tglEstRujuk,
        setfaskesrujukan,
        onChangeData,
        onCloseModal,
      }
      // console.log(propsmodal,'propsmodal');
      return handleModal(<SearchFaskesRujukan {...propsmodal} />, 'fit')
    }

    if (`${ariaLabel}`.includes('select-')) {
      actialfield = `${ariaLabel}`.replace('select-', '')
      if(actialfield == 'faskes'){
        removeLclStorage(`dataBpjs_faskes`, {type: 2})
      }
      if(actialfield == 'diagnosa3' && (!diagnosa3 || e.keyCode != 13)) return

      if(actialfield == 'search-diagnosa3'){
        actialfield = 'diagnosa3'
        if(!diagnosa3 || e.type != 'click') return
      }

      const listmodalprops = {
        alergimakanan: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-alergi-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'alergimakanan', jenisAlergi: '01'},
          fieldsearch: 'search',
          valuesearch: 'nmAlergi',
          placeholdersearch: 'Cari Alergi Makanan',
          title: 'Pilih Alergi Makanan',
          type: 'bpjs', //type search content
          selected: dataPcare?.kdalergimakanan,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdAlergi)
                setDataPcare({
                  ...dataPcare,
                  kdalergimakanan: im.kdAlergi ?? '',
                })

                setalergimakanan(`${im.kdAlergi} ${im.nmAlergi}`)

                onChangeData({
                  alergimakanan: `${im.kdAlergi} ${im.nmAlergi}`,
                  kdalergimakanan: im.kdAlergi ?? '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        alergiudara: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-alergi-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'alergiudara', jenisAlergi: '02'},
          fieldsearch: 'search',
          valuesearch: 'nmAlergi',
          placeholdersearch: 'Cari Alergi Udara',
          title: 'Pilih Alergi Udara',
          type: 'bpjs', //type search content
          selected: dataPcare?.kdalergiudara,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdAlergi)
                setDataPcare({
                  ...dataPcare,
                  kdalergiudara: im.kdAlergi ?? '',
                })

                setalergiudara(`${im.kdAlergi} ${im.nmAlergi}`)

                onChangeData({
                  alergiudara: `${im.kdAlergi} ${im.nmAlergi}`,
                  kdalergiudara: im.kdAlergi ?? '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        alergiobat: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-alergi-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'alergiobat', jenisAlergi: '03'},
          fieldsearch: 'search',
          valuesearch: 'nmAlergi',
          placeholdersearch: 'Cari Alergi Obat',
          title: 'Pilih Alergi Obat',
          type: 'bpjs', //type search content
          selected: dataPcare?.kdalergiobat,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdAlergi)
                setDataPcare({
                  ...dataPcare,
                  kdalergiobat: im.kdAlergi ?? '',
                })

                setalergiobat(`${im.kdAlergi} ${im.nmAlergi}`)

                onChangeData({
                  alergiobat: `${im.kdAlergi} ${im.nmAlergi}`,
                  kdalergiobat: im.kdAlergi ?? '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        prognosa: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-prognosa-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'prognosa'},
          fieldsearch: 'search',
          valuesearch: 'nmPrognosa',
          placeholdersearch: 'Cari Prognosa',
          title: 'Pilih Prognosa',
          type: 'bpjs', //type search content
          selected: dataPcare?.kdprognosa,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdPrognosa)
                setDataPcare({
                  ...dataPcare,
                  kdprognosa: im.kdPrognosa ?? '',
                })

                setprognosa(`${im.kdPrognosa} ${im.nmPrognosa}`)

                onChangeData({
                  prognosa: `${im.kdPrognosa} ${im.nmPrognosa}`,
                  kdprognosa: im.kdPrognosa ?? '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        diagnosa3: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'diagnosa', kdDiag: diagnosa3 || ''},
          fieldsearch: 'search',
          valuesearch: 'nmDiag',
          placeholdersearch: 'Cari Diagnosa',
          title: 'Pilih Diagnosa',
          type: 'bpjs', //type search content
          selected: dataPcare?.kdDiag,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdDiag)
                setDataPcare({
                  ...dataPcare,
                  kddiag3: im.kdDiag ?? '',
                })

                setdiagnosa3(`${im.kdDiag} ${im.nmDiag}`)

                onChangeData({
                  diagnosa3: `${im.kdDiag} ${im.nmDiag}`,
                  kddiag3: im.kdDiag ?? '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        kesadaran: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'kesadaran'},
          fieldsearch: 'search',
          valuesearch: 'nmSadar',
          placeholdersearch: 'Cari Kesadaran',
          title: 'Pilih Kesadaran',
          type: 'bpjs',
          selected: dataPcare?.kdSadar,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdSadar)
                setDataPcare({
                  ...dataPcare,
                  kdSadar: im.kdSadar ?? '',
                })

                setkesadaran({id: im.kdSadar, name: im.nmSadar})

                onChangeData({
                  kesadaran: {id: im.kdSadar, name: im.nmSadar},
                  kdkesadaran: im.kdSadar || '',
                })
                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        dokter: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'dokter'},
          fieldsearch: 'search',
          valuesearch: 'nmDokter',
          placeholdersearch: 'Cari Tenaga Medis',
          title: 'Pilih Tenaga Medis',
          type: 'bpjs',
          selected: dataPcare?.kdDokter,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdDokter)
                setDataPcare({
                  ...dataPcare,
                  kdDokter: im.kdDokter ?? '',
                })

                settenagamedis({id: im.kdDokter, name: im.nmDokter})

                onChangeData({
                  tenagamedis: {id: im.kdDokter, name: im.nmDokter},
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        statuspulang: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {uid: uid, reg: 'dbdok', a: 'dk13', type: 'statuspulang'},
          fieldsearch: 'search',
          valuesearch: 'nmStatusPulang',
          placeholdersearch: 'Cari Status Pulang',
          title: 'Pilih Status Pulang',
          type: 'bpjs',
          selected: statuspulang?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdStatusPulang)

                setDataPcare({
                  ...dataPcare,
                  kdStatusPulang: im.kdStatusPulang ?? '',
                  // nmStatusPulang: im.nmStatusPulang ?? '',
                })

                setstatuspulang({
                  id: im.kdStatusPulang,
                  name: im.nmStatusPulang,
                })

                onChangeData({
                  statuspulang: {
                    id: im.kdStatusPulang,
                    name: im.nmStatusPulang,
                  },
                  kdstatuspulang: im.kdStatusPulang || '',
                })

                let nmStatusPulang = im?.nmStatusPulang
                  ?.toLowerCase()
                  ?.includes('horizontal')

                if (im?.nmStatusPulang?.toLowerCase()?.includes('horizontal')) {
                  setJenisRujukan(1)
                  setListJenisRujukan([1])
                } else {
                  setJenisRujukan(2)
                  setListJenisRujukan([1, 2])
                }

                if (statuspulang?.id != im.kdStatusPulang) {
                  setspesialis({})
                  setsubspesialis({})
                  setsarana({})
                  setkhusus({})

                  onChangeData({
                    jenisRujukan: nmStatusPulang ? 1 : 2,

                    kdspesialis: '',
                    kdsubspesialis: '',
                    kdsarana: '',
                    kdkhusus: '',

                    spesialis: {},
                    subspesialis: {},
                    sarana: {},
                    khusus: {},
                    catatan: '',
                  })
                }

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        spesialis: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'spesialis',
            url: 'spesialis',
          },
          fieldsearch: 'search',
          valuesearch: 'nmSpesialis',
          placeholdersearch: 'Cari Spesialis',
          title: 'Pilih Spesialis',
          type: 'bpjs',
          selected: spesialis?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdSpesialis)

                setDataPcare({
                  ...dataPcare,
                  kdSpesialis: im.kdSpesialis ?? '',
                })

                setspesialis({
                  id: im.kdSpesialis,
                  name: im.nmSpesialis,
                })

                onChangeData({
                  spesialis: {
                    id: im.kdSpesialis,
                    name: im.nmSpesialis,
                  },
                  kdspesialis: im.kdSpesialis || '',
                })

                if (spesialis?.id != im.kdSpesialis) {
                  setsubspesialis({})
                  onChangeData({
                    subspesialis: {},
                    kdsubspesialis: '',
                  })
                }

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        subspesialis: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'subspesialis',
            url: `spesialis/${spesialis?.id}/subspesialis`,
          },
          fieldsearch: 'search',
          valuesearch: 'nmSubSpesialis',
          placeholdersearch: 'Cari Sub Spesialis',
          title: 'Pilih Sub Spesialis',
          type: 'bpjs',
          selected: subspesialis?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdSubspesialis)

                setDataPcare({
                  ...dataPcare,
                  kdSubspesialis: im.kdSubSpesialis ?? '',
                })

                setsubspesialis({
                  id: im.kdSubSpesialis,
                  name: im.nmSubSpesialis,
                })

                onChangeData({
                  subspesialis: {
                    id: im.kdSubSpesialis,
                    name: im.nmSubSpesialis,
                  },
                  kdsubspesialis: im.kdSubSpesialis || '',
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        sarana: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'sarana',
            url: `spesialis/sarana`,
          },
          fieldsearch: 'search',
          valuesearch: 'nmSarana',
          placeholdersearch: 'Cari Sarana',
          title: 'Pilih Sarana',
          type: 'bpjs',
          selected: sarana?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdSarana)

                setDataPcare({
                  ...dataPcare,
                  kdSarana: im.kdSarana ?? '',
                })

                setsarana({
                  id: im.kdSarana,
                  name: im.nmSarana,
                })

                onChangeData({
                  sarana: {
                    id: im.kdSarana,
                    name: im.nmSarana,
                  },
                  kdsarana: im.kdSarana || '',
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        khusus: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'khusus',
            url: `spesialis/khusus`,
          },
          fieldsearch: 'search',
          valuesearch: 'nmKhusus',
          placeholdersearch: 'Cari Khusus',
          title: 'Pilih Khusus',
          type: 'bpjs',
          selected: khusus?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdKhusus)

                setDataPcare({
                  ...dataPcare,
                  kdKhusus: im.kdKhusus ?? '',
                })

                setkhusus({
                  id: im.kdKhusus,
                  name: im.nmKhusus,
                })

                onChangeData({
                  khusus: {
                    id: im.kdKhusus,
                    name: im.nmKhusus,
                  },
                  kdkhusus: im.kdKhusus || '',
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        faskes: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-faskes-rujukan-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'faskes',
            jenisRujukan: jenisFaskes,
            subSpesialis: kdsubspesialis,
            sarana: kdsarana ? kdsarana : '',
            tglEstRujuk: tglEstRujuk,
            noKartu: noKartu,
            kdKhusus: kdkhusus,
          },
          fieldsearch: 'search',
          valuesearch: 'nmppk',
          placeholdersearch: 'Cari Faskes',
          title: 'Pilih Faskes',
          type: 'bpjs',
          selected: faskesrujukan?.kdppk,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdppk)

                setDataPcare({
                  ...dataPcare,
                  kdppk: im.kdppk ?? '',
                })

                setfaskesrujukan({kdppk: im?.kdppk, nmppk: im?.nmppk})
                onChangeData({
                  faskesrujukan: {kdppk: im?.kdppk, nmppk: im?.nmppk},
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        tacc: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'tacc',
          },
          fieldsearch: 'search',
          valuesearch: 'nmTacc',
          placeholdersearch: 'Cari Tacc',
          title: 'Pilih Tacc',
          type: 'bpjs',
          selected: tacc?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdTacc)

                setDataPcare({
                  ...dataPcare,
                  kdTacc: im.kdTacc ?? '',
                })

                settacc({
                  id: im.kdTacc,
                  name: im.nmTacc,
                })

                onChangeData({
                  tacc: {
                    id: im.kdTacc,
                    name: im.nmTacc,
                  },
                })

                if (im.kdTacc == '-1' || tacc?.id != im.kdTacc) {
                  setalasantacc({})
                  onChangeData({alasantacc: {}})
                }

                if (im.kdTacc == '3') {
                  setalasantacc({
                    id: 3,
                    name: kdata?.diagnosa,
                  })

                  onChangeData({alasantacc: {id: 3, name: kdata?.diagnosa}})
                }

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
        alasantacc: {
          key: Date.now(),
          url: APIBPJS + '/bpjs-jkn/get-data-pcare',
          variables: {
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'alasantacc',
          },
          fieldsearch: 'search',
          valuesearch: 'nmAlasanTacc',
          placeholdersearch: 'Cari Alasan Tacc',
          title: 'Pilih Alasan Tacc',
          type: 'bpjs',
          selected: tacc?.id,
          onCloseModal: () => onCloseModal(),
          renderItems: props => (
            <LisItems
              {...props}
              onPick={im => {
                props.onPick(im.kdAlasanTacc)

                setDataPcare({
                  ...dataPcare,
                  kdAlasanTacc: im.kdAlasanTacc ?? '',
                })

                setalasantacc({
                  id: im.kdAlasanTacc,
                  name: im.nmAlasanTacc,
                })

                onChangeData({
                  alasantacc: {
                    id: im.kdAlasanTacc,
                    name: im.nmAlasanTacc,
                  },
                })

                if (im) {
                  props.onCloseModal()
                }
              }}
            />
          ),
        },
      }
      const propsmodal = listmodalprops[actialfield] || {}
      return handleModal(<SearchContent {...propsmodal} />, 'fit')
    }
    if (ariaLabel === 'btn-add-pcare-obat') {
      return handleModal(
        <SearchObat key={`SO${Date.now()}`} fieldkey={fieldkey} optcarts={optcarts} noKunjungan={rdata?.kunpcarekunjungan} />,
        'middle'
      )
    }
    if (`${ariaLabel}`?.match(/^delete-item-obat-##/i)) {
      const splitters = `${ariaLabel}`.split('-##-')
      const idx = splitters[2]
      const item = cart[idx]
      return onDelete(item)
    }
    if (`${ariaLabel}`?.match(/^plus-item-obat-##/i)) {
      const splitters = `${ariaLabel}`.split('-##-')
      const idx = splitters[2]
      const item = cart[idx]
      item['qty'] += 1
      edititemcarts(item)
      return
    }
    if (`${ariaLabel}`?.match(/^minus-item-obat-##/i)) {
      const splitters = `${ariaLabel}`.split('-##-')
      const idx = splitters[2]
      const item = cart[idx]
      item['qty'] -= 1
      edititemcarts(item)
      return
    }
  }
  const onBlur = e => {
    // onBlur
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (`${ariaLabel}`?.match(/^input-qty-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        if (!!cartstoks[item[optcarts.flagstok]]) {
          const {stok} = cartstoks[item[optcarts.flagstok]]
          if (Number(value) <= 0) {
            return onDelete(item, () => {
              e['target']['value'] = item?.qty || 1
              closeAlert()
            })
          }
          if (Number(value) <= stok) {
            item['qty'] = Number(value)
            edititemcarts(item)
            return
          } else {
            if (Number(value) === Number(stok)) {
              return
            }
            e['target']['value'] = stok
            item['qty'] = stok
            edititemcarts(item)
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Stok obat <b>{item?.nmObat}</b> tidak mencukupi, sisa stok ={' '}
                  <b>{stok}</b>
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
        }
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }

  // UI State
  const [data, setData] = useState({})
  const [modalposition, setmodalposition] = useState(null)
  const [dataPcare, setDataPcare] = useState({})
  const [initialPcare, setInitialPcare] = useState({})
  const [pendaftaranPcare, setPendaftaranPcare] = useState({})
  const [kunjunganPcare, setKunjunganPcare] = useState({})
  const [dataKesadaran, setDataKesadaran] = useState([])
  const [dataStatusPulang, setDataStatusPulang] = useState([])
  const [dataDokter, setDataDokter] = useState([])
  const [dataSpesialis, setDataSpesialis] = useState([])
  const [dataSubSpesialis, setDataSubSpesialis] = useState([])
  const [dataSarana, setDataSarana] = useState([])
  const [dataKhusus, setDataKhusus] = useState([])
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)

  const [tindakanDokter, settindakanDokter] = useState([])
  const [tindakanPcare, settindakanPcare] = useState([])
  const [tindakanMerged, settindakanMerged] = useState([]);
  const [anamnesa, setanamnesa] = useState(kdata?.anamnesa || '')
  const [alergimakanan, setalergimakanan] = useState(kdata?.alergimakanan || '')
  const [alergiudara, setalergiudara] = useState(kdata?.alergiudara || '')
  const [alergiobat, setalergiobat] = useState(kdata?.alergiobat || '')
  const [prognosa, setprognosa] = useState(kdata?.prognosa || '')
  const [terapiobat, setterapiobat] = useState(kdata?.terapi || '')
  const [terapinonobat, setterapinonobat] = useState(kdata?.terapinonobat || '')
  const [bmhp, setbmhp] = useState(kdata?.bmhp || '')
  const [diagnosa3, setdiagnosa3] = useState(kdata?.kddiag3 || '')
  const [kesadaran, setkesadaran] = useState({})
  const [tenagamedis, settenagamedis] = useState({})
  const [spesialis, setspesialis] = useState({})
  const [subspesialis, setsubspesialis] = useState({})
  const [statuspulang, setstatuspulang] = useState({})
  const [sarana, setsarana] = useState({})
  const [khusus, setkhusus] = useState({})
  const [catatan, setcatatan] = useState('')
  const [faskesrujukan, setfaskesrujukan] = useState({})
  const [tacc, settacc] = useState({})
  const [alasantacc, setalasantacc] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [tglkunjunganpcare, settglkunjunganpcare] = useState(new Date())
  const [tglpulangpcare, settglpulangpcare] = useState(new Date())
  const [tglberkunjungpcare, settglberkunjungpcare] = useState(new Date())
  const [jenisRujukan, setJenisRujukan] = useState(1)
  const [listJenisRujukan, setListJenisRujukan] = useState([1, 2])
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredDataStatusPulang, setFilteredDataStatusPulang] = useState([])
  const [kdMCU, setkdMCU] = useState('')
  const [noKunjungan, setnoKunjungan] = useState('')
  const [kdProvider, setkdProvider] = useState('')
  const [tglPelayanan, settglPelayanan] = useState(new Date())
  const [tekananDarahSistole, settekananDarahSistole] = useState(0)
  const [tekananDarahDiastole, settekananDarahDiastole] = useState(0)
  const [radiologiFoto, setradiologiFoto] = useState(null)
  const [darahRutinHemo, setdarahRutinHemo] = useState(0)
  const [darahRutinLeu, setdarahRutinLeu] = useState(0)
  const [darahRutinErit, setdarahRutinErit] = useState(0)
  const [darahRutinLaju, setdarahRutinLaju] = useState(0)
  const [darahRutinHema, setdarahRutinHema] = useState(0)
  const [darahRutinTrom, setdarahRutinTrom] = useState(0)
  const [lemakDarahHDL, setlemakDarahHDL] = useState(0)
  const [lemakDarahLDL, setlemakDarahLDL] = useState(0)
  const [lemakDarahChol, setlemakDarahChol] = useState(0)
  const [lemakDarahTrigli, setlemakDarahTrigli] = useState(0)
  const [gulaDarahSewaktu, setgulaDarahSewaktu] = useState(0)
  const [gulaDarahPuasa, setgulaDarahPuasa] = useState(0)
  const [gulaDarahPostPrandial, setgulaDarahPostPrandial] = useState(0)
  const [gulaDarahHbA1c, setgulaDarahHbA1c] = useState(0)
  const [fungsiHatiSGOT, setfungsiHatiSGOT] = useState(0)
  const [fungsiHatiSGPT, setfungsiHatiSGPT] = useState(0)
  const [fungsiHatiGamma, setfungsiHatiGamma] = useState(0)
  const [fungsiHatiProtKual, setfungsiHatiProtKual] = useState(0)
  const [fungsiHatiAlbumin, setfungsiHatiAlbumin] = useState(0)
  const [fungsiGinjalCrea, setfungsiGinjalCrea] = useState(0)
  const [fungsiGinjalUreum, setfungsiGinjalUreum] = useState(0)
  const [fungsiGinjalAsam, setfungsiGinjalAsam] = useState(0)
  const [fungsiJantungABI, setfungsiJantungABI] = useState(0)
  const [fungsiJantungEKG, setfungsiJantungEKG] = useState(null)
  const [fungsiJantungEcho, setfungsiJantungEcho] = useState(null)
  const [funduskopi, setfunduskopi] = useState(null)
  const [pemeriksaanLain, setpemeriksaanLain] = useState(null)
  const [keterangan, setketerangan] = useState(null)

  const {
    cart,
    carts,
    cartstoks,
    addtocarts,
    edititemcarts,
    removetocarts,
    setcarts,
  } = useCarts(optcarts)
  // Api State
  const [apiParams, setApiParams] = useState(initialApiParams)

  useEffect(() => {
    const kunuid = searchParams.get('kid')
    uid = searchParams.get('dctid')

    // load tindakan yang sudah disimpan dari pembayaran
    axios
      .post(
        `${APIDOC}/prd-lap-rekammedis/get-tindakan`,
        qs.stringify({
          uid: uid,
          kid: kunuid,
        })
      )
      .then(({data}) => {
        // console.error('tindakanDokter data', data)
        if (data?.status == 1) {
          let resData = data?.data
          let tindakanBpjs = resData.filter(i => i.biakodebpjs != '')
          let finalTindakan =
            data?.data.length == 0
              ? []
              : tindakanBpjs.length == 0
              ? 'NotSync'
              : tindakanBpjs
          // console.error('resData', resData)
          settindakanDokter(finalTindakan)
        } else {
          console.log('Data tidak ditemukan tindakan')
        }
      })
      .catch(e => {
        console.log('Gagal mengambil data tindakan')
      })
    // setDataPcare()
  }, [])

  useEffect(() => {
    console.log('kdata', kdata)
    console.log('rdata', rdata)

    loadKesadaran()
    loadStatusPulang()
    loadDataDokter()
    loadDataSpesialis()
    loadDataSarana()
    loadDataKhusus()
    loadTacc()
    loadPrognosa()
    loadAlergi('01')
    loadAlergi('02')
    loadAlergi('03')

    setPendaftaranPcare(kdata?.pcaredata || {})
    settenagamedis(kdata?.tenagamedis || {})
    settacc(kdata?.tacc || {})
    setalasantacc(kdata?.alasantacc || {})
    setcatatan(kdata?.catatan || '')
    setJenisRujukan(kdata?.jenisRujukan || 1)
    setfaskesrujukan(kdata?.faskesrujukan || {})

    settglkunjunganpcare(kdata?.tglkunjunganpcare || new Date())
    settglpulangpcare(kdata?.tglpulangpcare || new Date())
    settglberkunjungpcare(kdata?.tglberkunjungpcare || new Date())

    // if (!kdata?.id) {
      setkesadaran(kdata?.kesadaran || {})
      setspesialis(kdata?.spesialis || {})
      setsubspesialis(kdata?.subspesialis || {})
      setstatuspulang(kdata?.statuspulang || {})
      setsarana(kdata?.sarana || {})
      setkhusus(kdata?.khusus || {})
    // }

    if (!rdata?.kunpcarekunjungan) {
      onChangeData({
        tglkunjunganpcare: new Date(),
        tglberkunjungpcare: new Date(),
        tglpulangpcare: new Date(),
        pcaretglpulang: moment().format('YYYY-MM-DD'),
      })
    } else {
      loadDataMcu()
    }
    
    var timeout = setTimeout(() => {
      const elBtnHideMCU = getElement({
        equal: 'hide-mcu',
        tag: 'button',
      })
      if (elBtnHideMCU?.ariaLabel === 'hide-mcu') {
        const isShown = headmcu[0] === elBtnHideMCU?.innerText
        if (isShown) {
          elBtnHideMCU?.click()
        }
      }
      if (timeout) {
        clearTimeout(timeout)
      }
    }, 500)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [kdata, rdata])

  useEffect(() => {
    onFetchTindakan({kunpcarekunjungan: rdata?.kunpcarekunjungan})
  }, [rdata, tindakanDokter])

  useEffect(() => {
    if (spesialis?.id) {
      loadDataSubSpesialis()
    }
  }, [spesialis])

  useEffect(() => {
    // if(tacc?.id != '-1'){
    loadTacc()
    // }
  }, [tacc])

  const handleError = (e = '') => {
    setError({e: e ?? '', message: 'Terjadi kesalahan saat mengambil data'})
  }

  const handleJenisRujukan = e => {
    if (jenisRujukan != parseInt(e.target.value)) {
      setspesialis({})
      setsubspesialis({})
      setsarana({})
      setkhusus({})

      onChangeData({
        spesialis: {},
        subspesialis: {},
        sarana: {},
        khusus: {},
        catatan: '',
      })
    }
    setJenisRujukan(parseInt(e.target.value))
    onChangeData({
      jenisRujukan: parseInt(e.target.value),
    })
  }

  // Filtering the data based on the search term
  const handleSearch = e => {
    const term = e.target.value
    setSearchTerm(term)
    const filtered = dataStatusPulang.filter(
      kec =>
        kec.kdStatusPulang.toLowerCase().includes(term.toLowerCase()) ||
        kec.nmStatusPulang.toLowerCase().includes(term.toLowerCase())
    )
    setFilteredDataStatusPulang(filtered)
  }

  const loadTacc = () => {
    const dataTacc = [
      {
        kdTacc: '-1',
        nmTacc: 'Tanpa TACC',
        // alasanTacc: [],
      },
      {
        kdTacc: '1',
        nmTacc: 'Time',
        // alasanTacc: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
      },
      {
        kdTacc: '2',
        nmTacc: 'Age',
        // alasanTacc: [
        //   '< 1 Bulan',
        //   '>= 1 Bulan s/d < 12 Bulan',
        //   '>= 1 Tahun s/d < 5 Tahun',
        //   '>= 5 Tahun s/d < 12 Tahun',
        //   '>= 12 Tahun s/d < 55 Tahun',
        //   '>= 55 Tahun',
        // ],
      },
      {
        kdTacc: '3',
        nmTacc: 'Complication',
        alasanTacc: 'Diagnosa Utama',
        // alasanTacc: [],
        // 'format' : $kdDiagnosa + " - " + $NamaDiagnosa,
        // 'contoh' : "A09 - Diarrhoea and gastroenteritis of presumed infectious origin"
      },
      {
        kdTacc: '4',
        nmTacc: 'Comorbidity',
        // alasanTacc: ['< 3 Hari', '>= 3 - 7 Hari', '>= 7 Hari'],
      },
    ]
    const alasanTime = [
      {
        kdAlasanTacc: '< 3 Hari',
        nmAlasanTacc: '< 3 Hari',
      },
      {
        kdAlasanTacc: '>= 3 - 7 Hari',
        nmAlasanTacc: '>= 3 - 7 Hari',
      },
      {
        kdAlasanTacc: '>= 7 Hari',
        nmAlasanTacc: '>= 7 Hari',
      },
    ]

    const alasanAge = [
      {
        kdAlasanTacc: '< 1 Bulan',
        nmAlasanTacc: '< 1 Bulan',
      },
      {
        kdAlasanTacc: '>= 1 Bulan s/d < 12 Bulan',
        nmAlasanTacc: '>= 1 Bulan s/d < 12 Bulan',
      },
      {
        kdAlasanTacc: '>= 1 Tahun s/d < 5 Tahun',
        nmAlasanTacc: '>= 1 Tahun s/d < 5 Tahun',
      },
      {
        kdAlasanTacc: '>= 5 Tahun s/d < 12 Tahun',
        nmAlasanTacc: '>= 5 Tahun s/d < 12 Tahun',
      },
      {
        kdAlasanTacc: '>= 12 Tahun s/d < 55 Tahun',
        nmAlasanTacc: '>= 12 Tahun s/d < 55 Tahun',
      },
      {
        kdAlasanTacc: '>= 55 Tahun',
        nmAlasanTacc: '>= 55 Tahun',
      },
    ]

    if (tacc?.id == '2') {
      setLclStorage(`dataBpjs_alasantacc`, JSON.stringify(alasanAge), {type: 2})
    } else if (tacc?.id == '1' || tacc?.id == '4') {
      setLclStorage(`dataBpjs_alasantacc`, JSON.stringify(alasanTime), {type: 2})
    } else {
      removeLclStorage(`dataBpjs_alasantacc`, {type: 2})
    }

    setLclStorage(`dataBpjs_tacc`, JSON.stringify(dataTacc), {type: 2})
  }

  const loadPendaftaranPcare = async => {
    const lname = 'dataBpjs_pendaftaran'
    const dataBpjs = getLclStorage(lname, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      // console.log(ldataBpjs, 'ldataBpjs')
      // setPendaftaranPcare(ldataBpjs)
      // onChangeData({
      //   ['pcareloaded']: true,
      //   ['keluhanutama']: ldataBpjs?.keluhan,
      //   ['tinggi']: ldataBpjs?.tinggiBadan,
      //   ['berat']: ldataBpjs?.beratBadan,
      //   ['tekanandarah_systolic']: ldataBpjs?.sistole,
      //   ['tekanandarah_diastolic']: ldataBpjs?.diastole,
      //   ['nafas']: ldataBpjs?.respRate,
      //   ['nadi']: ldataBpjs?.heartRate,
      //   pcaredata: ldataBpjs,
      // })
    } else {
      // if (rdata?.kunpcareno && rdata?.date) {
      axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-pendaftaran-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'pendaftaran',
            // noUrut: 'A1',
            noUrut: rdata?.kunpcareno,
            // tglDaftar: '12-01-2024',
            tglDaftar: moment(rdata?.date).utc().format('DD-MM-yyyy'),
          })
        )
        .then(({data}) => {
          console.log(data, 'getDataPendaftaran')
          if (data?.metaData?.code == 200) {
            // setPendaftaranPcare(data?.response)

            // onChangeData({
            //   ['pcareloaded']: true,
            //   ['keluhanutama']: data?.response?.keluhan,
            //   ['tinggi']: data?.response?.tinggiBadan,
            //   ['berat']: data?.response?.beratBadan,
            //   ['tekanandarah_systolic']: data?.response?.sistole,
            //   ['tekanandarah_diastolic']: data?.response?.diastole,
            //   ['nafas']: data?.response?.respRate,
            //   ['nadi']: data?.response?.heartRate,
            //   pcaredata: data?.response,
            // })

            setLclStorage(lname, JSON.stringify(data?.response), {type: 2})
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
    }
    // }
  }

  const loadKunjunganPcare = async => {
    const lname = 'dataBpjs_kunjungan'
    const dataBpjs = getLclStorage(lname, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      // console.log(ldataBpjs, 'ldataBpjs')
      setKunjunganPcare(ldataBpjs)
    } else {
      // if (rdata?.kunpcarekunjungan) {
      axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-kunjungan-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'kunjungan',
            noKunjungan: 'A1',
            // noKunjungan: rdata?.kunpcarekunjungan,
          })
        )
        .then(({data}) => {
          console.log(data, 'getDataKunjungan')
          if (data?.metaData?.code == 200) {
            setKunjunganPcare(data?.response)
            setLclStorage(lname, JSON.stringify(data?.response), {type: 2})
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
      // }
    }
  }

  const loadAlergi= async (type) => {
    let lname = 'dataBpjs_alergimakanan'
    let value = kdata?.kdalergimakanan
    switch (type) {
      case '01':
        lname = 'dataBpjs_alergimakanan'
        value = kdata?.kdalergimakanan
      break;
      case '02':
        lname = 'dataBpjs_alergiudara'
        value = kdata?.kdalergiudara
      break;
      case '03':
        lname = 'dataBpjs_alergiobat'
        value = kdata?.kdalergiobat
      break;
      default:
        lname = 'dataBpjs_alergimakanan'
        value = kdata?.kdalergimakanan
      break;
    }
    const dataAlergi = getLclStorage(lname, {type: 2})
    const ldataAlergi = dataAlergi ? JSON.parse(dataAlergi) : []
    if (ldataAlergi && ldataAlergi.length > 0) {
      if (value) {
        const kdalergi = await ldataAlergi.find(
          item => item.kdAlergi === value
        )

        switch (type) {
          case '01':
            setalergimakanan(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
            onChangeData({
              alergimakanan: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
              kdalergimakanan: kdalergi.kdAlergi || '',
            })
          break;
          case '02':
            setalergiudara(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
            onChangeData({
              alergiudara: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
              kdalergiudara: kdalergi.kdAlergi || '',
            })
          break;
          case '03':
            setalergiobat(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
            onChangeData({
              alergiobat: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
              kdalergiobat: kdalergi.kdAlergi || '',
            })
          break;
          default:
          break;
        }
      
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-alergi-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            jenisAlergi: type
          })
        )
        .then(({data}) => {
          console.log(data, 'loadAlergi')
          if (data?.metaData?.code == 200) {
            const ldata = data?.response?.list

            if (value) {
              const kdalergi = ldata?.find(
                item => item.kdAlergi === value
              )

              switch (type) {
                case '01':
                  setalergimakanan(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
                  onChangeData({
                    alergimakanan: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
                    kdalergimakanan: kdalergi.kdAlergi || '',
                  })
                break;
                case '02':
                  setalergiudara(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
                  onChangeData({
                    alergiudara: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
                    kdalergiudara: kdalergi.kdAlergi || '',
                  })
                break;
                case '03':
                  setalergiobat(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
                  onChangeData({
                    alergiobat: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
                    kdalergiobat: kdalergi.kdAlergi || '',
                  })
                break;
                default:
                  // setalergimakanan(`${kdalergi.kdAlergi} ${kdalergi.nmAlergi}`)
                  // onChangeData({
                  //   alergimakanan: {id: kdalergi.kdAlergi, name: kdalergi.nmAlergi},
                  //   kdalergimakanan: kdalergi.kdAlergi || '',
                  // })
                break;
              }
            }

            setLclStorage(lname, JSON.stringify(data?.response?.list), {type: 2})
          } else {
            console.log(
              data?.metaData?.message ?? 'Data Alergi tidak ditemukan'
            )
          }
        })
        .catch(e => {
          console.log('Gagal mengambil Data Alergi')
        })
    }
  }

  const loadPrognosa= async () => {
    const lname = 'dataBpjs_prognosa'
    const dataPrognosa = getLclStorage(lname, {type: 2})
    const ldataPrognosa = dataPrognosa ? JSON.parse(dataPrognosa) : []
    if (ldataPrognosa && ldataPrognosa.length > 0) {
      if (kdata?.kdprognosa) {
        const kdprognosa = await ldataPrognosa.find(
          item => item.kdPrognosa === kdata?.kdprognosa
        )

        setprognosa(`${kdprognosa.kdPrognosa} ${kdprognosa.nmPrognosa}`)
        onChangeData({
          prognosa: `${kdprognosa.kdPrognosa} ${kdprognosa.nmPrognosa}`,
          kdprognosa: kdprognosa.kdPrognosa || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-prognosa-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'prognosa',
          })
        )
        .then(({data}) => {
          console.log(data, 'loadPrognosa')
          if (data?.metaData?.code == 200) {
            const ldata = data?.response?.list

            if (kdata?.kdprognosa) {
              const kdprognosa = ldata?.find(
                item => item.kdPrognosa === kdata?.kdprognosa
              )

              setprognosa(`${kdprognosa.kdPrognosa} ${kdprognosa.nmPrognosa}`)
              onChangeData({
                prognosa: `${kdprognosa.kdPrognosa} ${kdprognosa.nmPrognosa}`,
                kdprognosa: kdprognosa.kdPrognosa || '',
              })
            }

            setLclStorage(lname, JSON.stringify(data?.response?.list), {type: 2})
          } else {
            console.log(
              data?.metaData?.message ?? 'Data Prognosa tidak ditemukan'
            )
          }
        })
        .catch(e => {
          console.log('Gagal mengambil Data Prognosa')
        })
    }
  }

  const loadKesadaran = async () => {
    const lname = 'dataBpjs_kesadaran'
    const dataKesadaran = getLclStorage(lname, {type: 2})
    const ldataKesadaran = dataKesadaran ? JSON.parse(dataKesadaran) : []
    if (ldataKesadaran && ldataKesadaran.length > 0) {
      if (kdata?.kdkesadaran) {
        const kdkesadaran = await ldataKesadaran?.find(
          item => item.kdSadar === kdata?.kdkesadaran
        )
        setkesadaran({id: kdkesadaran?.kdSadar, name: kdkesadaran?.nmSadar})
        onChangeData({
          kesadaran: {id: kdkesadaran.kdSadar, name: kdkesadaran.nmSadar},
          kdkesadaran: kdkesadaran.kdSadar || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'kesadaran',
          })
        )
        .then(({data}) => {
          console.log(data, 'loadKesadaran')
          if (data?.metaData?.code == 200) {
            setDataKesadaran(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdkesadaran) {
              const kdkesadaran = ldata?.find(
                item => item.kdSadar === kdata?.kdkesadaran
              )

              setkesadaran({
                id: kdkesadaran?.kdSadar,
                name: kdkesadaran?.nmSadar,
              })

              onChangeData({
                kesadaran: {id: kdkesadaran.kdSadar, name: kdkesadaran.nmSadar},
                kdkesadaran: kdkesadaran.kdSadar || '',
              })
            }

            setLclStorage(lname, JSON.stringify(data?.response?.list), {type: 2})
          } else {
            console.log(
              data?.metaData?.message ?? 'Data Kesadaran tidak ditemukan'
            )
          }
        })
        .catch(e => {
          console.log('Gagal mengambil Data Kesadaran')
        })
    }
  }

  const loadStatusPulang = async () => {
    const lname = 'statuspulang'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    // console.log(dataStatusPulang, 'dataStatusPulang')
    // console.log(ldataBpjs, 'ldataBpjs')
    if (ldataBpjs && ldataBpjs.length > 0) {
      setDataStatusPulang(ldataBpjs)

      if (kdata?.kdstatuspulang) {
        const findData = ldataBpjs?.find(
          item => item.kdStatusPulang === kdata?.kdstatuspulang
        )

        setstatuspulang({
          id: findData?.kdStatusPulang,
          name: findData?.nmStatusPulang,
        })

        onChangeData({
          statuspulang: {
            id: findData?.kdStatusPulang,
            name: findData?.nmStatusPulang,
          },
          kdstatuspulang: findData?.kdStatusPulang || '',
        })
      }
      // console.log(kdata?.kdstatuspulang, 'kdata?.kdstatuspulang')
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
          })
        )
        .then(({data}) => {
          // console.log(data, 'loadStatusPulang')
          if (data?.metaData?.code == 200) {
            setDataStatusPulang(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdstatuspulang) {
              const findData = ldata?.find(
                item => item.kdStatusPulang === kdata?.kdstatuspulang
              )

              setstatuspulang({
                id: findData?.kdStatusPulang,
                name: findData?.nmStatusPulang,
              })

              onChangeData({
                statuspulang: {
                  id: findData?.kdStatusPulang,
                  name: findData?.nmStatusPulang,
                },
                kdstatuspulang: findData?.kdStatusPulang || '',
              })
            }

            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataDokter = async () => {
    const lname = 'dokter'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    // if (ldataBpjs && ldataBpjs.length > 0) {
    //   setDataDokter(ldataBpjs)
    // } else {
    await axios
      .post(
        `${APIBPJS}/bpjs-jkn/get-data-pcare`,
        qs.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: lname,
        })
      )
      .then(({data}) => {
        // console.log(data, 'getDataBpjs')
        if (data?.metaData?.code == 200) {
          setDataDokter(data?.response?.list)
          setLclStorage(
            `dataBpjs_${lname}`,
            JSON.stringify(data?.response?.list),
            {type: 2}
          )
        } else {
          console.log('Data tidak ditemukan')
        }
      })
      .catch(e => {
        console.log('Gagal mengambil data')
      })
    // }
  }

  const loadDataSpesialis = async () => {
    const lname = 'spesialis'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      setDataSpesialis(ldataBpjs)
      if (kdata?.kdspesialis) {
        const findData = await ldataBpjs?.find(
          item => item.kdSpesialis === kdata?.kdspesialis
        )

        setspesialis({id: findData?.kdSpesialis, name: findData?.nmSpesialis})

        onChangeData({
          spesialis: {
            id: findData?.kdSpesialis,
            name: findData?.nmSpesialis,
          },
          kdspesialis: findData?.kdSpesialis || '',
        })
      }

      // console.log(ldataBpjs, 'ldataBpjs')
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: lname,
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            setDataSpesialis(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdspesialis) {
              const findData = ldata?.find(
                item => item.kdSpesialis === kdata?.kdspesialis
              )

              setspesialis({
                id: findData?.kdSpesialis,
                name: findData?.nmSpesialis,
              })

              onChangeData({
                spesialis: {
                  id: findData?.kdSpesialis,
                  name: findData?.nmSpesialis,
                },
                kdspesialis: findData?.kdSpesialis || '',
              })
            }
            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataSubSpesialis = async () => {
    const lname = 'subspesialis'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    // console.log(spesialis, 'spesialis')
    // if (ldataBpjs && ldataBpjs.length > 0) {
    // console.log(ldataBpjs, 'ldataBpjs')
    // } else {
    await axios
      .post(
        `${APIBPJS}/bpjs-jkn/get-data-pcare`,
        qs.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: lname,
          url: `spesialis/${spesialis?.id}/subspesialis`,
        })
      )
      .then(({data}) => {
        // console.log(data, 'getDataBpjs')
        if (data?.metaData?.code == 200) {
          setDataSubSpesialis(data?.response?.list)
          const ldata = data?.response?.list

          if (kdata?.kdsubspesialis) {
            const findData = ldata?.find(
              item => item.kdSubSpesialis === kdata?.kdsubspesialis
            )

            setsubspesialis({
              id: findData?.kdSubSpesialis,
              name: findData?.nmSubSpesialis,
            })

            onChangeData({
              subspesialis: {
                id: findData?.kdSubSpesialis,
                name: findData?.nmSubSpesialis,
              },
              kdsubspesialis: findData?.kdSubSpesialis || '',
            })
          }
          setLclStorage(
            `dataBpjs_${lname}`,
            JSON.stringify(data?.response?.list),
            {type: 2}
          )
        } else {
          console.log('Data tidak ditemukan')
        }
      })
      .catch(e => {
        console.log('Gagal mengambil data')
      })
    // }
  }

  const loadDataSarana = async () => {
    const lname = 'sarana'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      setDataSarana(ldataBpjs)
      if (kdata?.kdsarana) {
        const findData = await ldataBpjs?.find(
          item => item.kdSarana === kdata?.kdsarana
        )

        setsarana({id: findData?.kdSarana, name: findData?.nmSarana})

        onChangeData({
          sarana: {
            id: findData?.kdSarana,
            name: findData?.nmSarana,
          },
          kdsarana: findData?.kdSarana || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: 'spesialis/sarana',
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            setDataSarana(data?.response?.list)
            const ldata = data?.response?.list
            if (kdata?.kdsarana) {
              const findData = ldata?.find(
                item => item.kdSarana === kdata?.kdsarana
              )

              setsarana({id: findData?.kdSarana, name: findData?.nmSarana})

              onChangeData({
                sarana: {
                  id: findData?.kdSarana,
                  name: findData?.nmSarana,
                },
                kdsarana: findData?.kdSarana || '',
              })
            }
            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataKhusus = async () => {
    const lname = 'khusus'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      setDataKhusus(ldataBpjs)
      if (kdata?.kdkhusus) {
        const findData = await ldataBpjs?.find(
          item => item.kdKhusus === kdata?.kdkhusus
        )

        setkhusus({id: findData?.kdKhusus, name: findData?.nmKhusus})

        onChangeData({
          khusus: {
            id: findData?.kdKhusus,
            name: findData?.nmKhusus,
          },
          kdkhusus: findData?.kdKhusus || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          qs.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: 'spesialis/khusus',
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            setDataKhusus(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdkhusus) {
              const findData = ldata?.find(
                item => item.kdKhusus === kdata?.kdkhusus
              )

              setkhusus({id: findData?.kdKhusus, name: findData?.nmKhusus})

              onChangeData({
                khusus: {
                  id: findData?.kdKhusus,
                  name: findData?.nmKhusus,
                },
                kdkhusus: findData?.kdKhusus || '',
              })
            }

            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          console.log('Gagal mengambil data')
        })
    }
  }

  const onFetchTindakan = (data, auto = false) => {
    const kunid = searchParams.get('kid')
    uid = searchParams.get('dctid')
    if(auto)setLoading(true)
    // return true
    axios
      .post(
        `${APIBPJS}/bpjs-jkn/tindakan-pcare`,
        qs.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: 'tindakan',
          method: 'GET',
          data: {
            noKunjungan: data?.kunpcarekunjungan,
          },
        })
      )
      .then(({data: resdata = {}}) => {
        if (resdata?.metaData?.code == 200) {
          var tindakan = resdata?.response?.list
          var biakodebpjs = tindakan.map(item => item.kdTindakan).join(',')
          // var biakodebpjs = '01023, 01006'

          axios
            .post(
              `${APIDOC}/prd-lap-rekammedis/get-tindakan-pcare`,
              qs.stringify({
                a: 'dk13',
                uid: uid,
                kid: kunid,
                biakodebpjs,
              })
            )
            .then(({data: res = {}}) => {
              // console.error('res', res)
              const tindakanPcareData = res;
              settindakanPcare(tindakanPcareData);

              // Process to merge tindakan and tindakanPcare and set status
              const mergedData = tindakanPcareData.map(item => {
                const isPaid = tindakanDokter.some(pcItem => pcItem.biakodebpjs == item.biakodebpjs);
                const kdtindakansk = tindakan.find(pcItem => pcItem.kdTindakan == item.biakodebpjs)?.kdTindakanSK;
                return {
                  ...item,
                  status: isPaid ? 'Paid' : 'Unpaid',
                  kdtindakansk
                };
              });

              settindakanMerged(mergedData);
              if(auto)setLoading(false)

            })
            .catch(ch => {
              // console.error('onFetchTindakan:ch', ch)
              if(auto)setLoading(false)

            })
            .finally(() => {
              // console.error('onFetchTindakan:finish')
              if(auto)setLoading(false)

            })
        }else{
          settindakanMerged([])
        }
        
      })
      .catch(ch => {
        // console.error('onFetchTindakan:ch', ch)
        if(auto)setLoading(false)
      })
      .finally(() => {
        console.log('onFetchTindakan:finally')
        if(auto)setLoading(false)
      })
  }

  const deleteTindakanPcare = useCallback(
    (e, data, kunpcarekunjungan) => {
      if (kunpcarekunjungan && data?.kdtindakansk) {
        setLoading(true)
    
        let deleteTindakan = {
          kdTindakanSK: data?.kdtindakansk || 0,
          noKunjungan: kunpcarekunjungan || '',
          kdTindakan: data?.biakodebpjs || '',
        }

        // console.error('kunpcarekunjungan', kunpcarekunjungan)
        // setLoading(false)
        // return true
         axios
          .post(
            `${APIBPJS}/bpjs-jkn/tindakan-pcare`,
            qs.stringify({
              uid: uid,
              reg: 'dbdok',
              a: 'dk13',
              type: 'tindakan',
              method: 'DELETE',
              data: deleteTindakan,
            })
          )
          .then(({data: resdata = {}}) => {
            // console.error('delete-tindakan-pcare', resdata)
            if (resdata?.metaData?.message?.toLocaleLowerCase().includes('ok')) {
            
              setLoading(false)
              return setpropsalertform({
                ...propsalertform,
                type: 1,
                visible: true,
                title: 'Hapus Tindakan',
                children: (
                  <>
                    Tindakan berhasil dihapus dari Pcare.
                  </>
                ),
                onConfirm: () => onFetchTindakan({kunpcarekunjungan}, true),
                onClose: closeAlert,
                onCancel: closeAlert,
              })
              // tFormRef?.okRef?.show(
              //   {
              //     title: 'Berhasil',
              //     message: 'Tindakan berhasil dihapus dari PCare',
              //     type: 'success',
              //   },
              //   () => {},
              //   () => {}
              // )
            } else {
              // console.error('failed-delete-tindakan-pcare', resdata)
              let countRes = resdata?.response ? resdata?.response.length : 1
              var message =
                resdata?.response && resdata?.response.length > 0
                  ? `${resdata?.response[0].field} ${resdata?.response[0].message}`
                  : ''
              let metadataMessage =
                resdata?.metaData && resdata?.metaData.message
                  ? resdata?.metaData.message
                  : ''
  
              if (countRes > 1) {
                let mergedMessages = ''
                resdata?.response.forEach(item => {
                  mergedMessages += `${item.field} ${item.message}, `
                })
                message = mergedMessages.trim()
              }
  
              if (!message && metadataMessage) {
                message = metadataMessage
              }
  
              message = message
                ? message
                : `Gagal menghapus Tindakan dari PCare dengan No. Kunjungan ${rdata?.kunpcarekunjungan}`
  
              setLoading(false)
              return setpropsalertform({
                ...propsalertform,
                type: 3,
                visible: true,
                title: 'Hapus Tindakan',
                children: (
                  <>
                    {message}
                  </>
                ),
                onConfirm: closeAlert,
                onClose: closeAlert,
                onCancel: closeAlert,
              })
            }
          })
          .catch(e => {
            // console.error('error-delete-tindakan-pcare', e)
            setLoading(false)
            return setpropsalertform({
              ...propsalertform,
              type: 2,
              visible: true,
              title: 'Hapus Tindakan',
              children: (
                <>
                  {'Gagal menghapus Tindakan dari Pcare'}
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
              onCancel: closeAlert,
            })
          })
      }
    },
    [closeAlert]
  )

  const onDeleteTindakan = (e, data, kunpcarekunjungan) => {
    if (kunpcarekunjungan && data?.kdtindakansk) {
      return setpropsalertform({
        ...propsalertform,
        type: 2,
        visible: true,
        title: 'Hapus Tindakan',
        children: (
          <>
            Apakah Anda yakin akan menghapus Tindakan <b>{data?.bianama}</b>?
          </>
        ),
        onConfirm: () => deleteTindakanPcare(e, data, kunpcarekunjungan),
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
  }

  const loadDataMcu = () => {
    setkdMCU(kdata?.kdMCU)
    setnoKunjungan(kdata?.noKunjungan)
    setkdProvider(kdata?.kdProvider)
    settglPelayanan(kdata?.tglPelayanan)
    settekananDarahSistole(kdata?.tekananDarahSistole)
    settekananDarahDiastole(kdata?.tekananDarahDiastole)
    setradiologiFoto(kdata?.radiologiFoto)
    setdarahRutinHemo(kdata?.darahRutinHemo)
    setdarahRutinLeu(kdata?.darahRutinLeu)
    setdarahRutinErit(kdata?.darahRutinErit)
    setdarahRutinLaju(kdata?.darahRutinLaju)
    setdarahRutinHema(kdata?.darahRutinHema)
    setdarahRutinTrom(kdata?.darahRutinTrom)
    setlemakDarahHDL(kdata?.lemakDarahHDL)
    setlemakDarahLDL(kdata?.lemakDarahLDL)
    setlemakDarahChol(kdata?.lemakDarahChol)
    setlemakDarahTrigli(kdata?.lemakDarahTrigli)
    setgulaDarahSewaktu(kdata?.gulaDarahSewaktu)
    setgulaDarahPuasa(kdata?.gulaDarahPuasa)
    setgulaDarahPostPrandial(kdata?.gulaDarahPostPrandial)
    setgulaDarahHbA1c(kdata?.gulaDarahHbA1c)
    setfungsiHatiSGOT(kdata?.fungsiHatiSGOT)
    setfungsiHatiSGPT(kdata?.fungsiHatiSGPT)
    setfungsiHatiGamma(kdata?.fungsiHatiGamma)
    setfungsiHatiProtKual(kdata?.fungsiHatiProtKual)
    setfungsiHatiAlbumin(kdata?.fungsiHatiAlbumin)
    setfungsiGinjalCrea(kdata?.fungsiGinjalCrea)
    setfungsiGinjalUreum(kdata?.fungsiGinjalUreum)
    setfungsiGinjalAsam(kdata?.fungsiGinjalAsam)
    setfungsiJantungABI(kdata?.fungsiJantungABI)
    setfungsiJantungEKG(kdata?.fungsiJantungEKG)
    setfungsiJantungEcho(kdata?.fungsiJantungEcho)
    setfunduskopi(kdata?.funduskopi)
    setpemeriksaanLain(kdata?.pemeriksaanLain)
    setketerangan(kdata?.keterangan)
  }

  useAddEventListener({eventName: 'click', handler: onClickData})
  useAddEventListener({eventName: 'focusout', handler: onBlur})
  return (
    <Fragment>
      {loading ? (
        <VmView className="flex justify-center items-center mb-5">
          <VmSpinner color="sky-500" />
        </VmView>
      ) : error ? (
        <VmView className="flex justify-center items-center mb-5">
          <VmText>{`${error}`}</VmText>
        </VmView>
      ) : (
        <VmView
        // ref={formRef}
        // loading={loading}
        // onChangeCapture={onChangeCapture}
        // onErrorCapture={onErrorCapture}
        // onClick={onClickData}
        >
          <VmView className={classNames(classSparator, '')} />
          <VmView className={containerClassName}>
            <VmView
              className={classNames(
                titleClassName,
                'flex justify-between items-center'
              )}
            >
              <VmText className="">{'Pemeriksaan Pcare'}</VmText>
            </VmView>

            <Fragment>
              {/* Start Entri Pendaftaran Pcare */}
              <VmView
                // key={`${JSON.stringify({im})}${idxs}`}
                className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
              >
                <VmText className="text-slate-800 font-bold text-xl mb-3">
                  Pendaftaran
                </VmText>
                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Tanggal Pendaftaran'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {moment(rdata?.date).utc().format('DD MMM YYYY')}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Poli'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.poli?.kdPoli
                        ? `${kdata?.pcaredata?.poli?.kdPoli} - ${kdata?.pcaredata?.poli?.nmPoli}`
                        : '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'No. Pendaftaran'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.noUrut
                        ? kdata?.pcaredata?.noUrut
                        : rdata?.kunpcareno ?? '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'No. Kartu BPJS'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.peserta?.noKartu
                        ? kdata?.pcaredata?.peserta?.noKartu
                        : rdata?.pasnobpjs ?? '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Nama'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {rdata?.patname ?? '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Tanggal Lahir'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {moment(rdata?.born).utc().format('DD MMM YYYY') ?? '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Jenis Kelamin'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {rdata?.sex ?? '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'No. Handphone'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {rdata?.phone ?? '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'No. Rekam Medis'}
                  </VmText>
                  <VmText className="text-slate-600 text-base text-break-word">
                    {rdata?.rm ?? '-'}
                  </VmText>
                </VmView>
              </VmView>
              {/* End Entri Pendaftaran Pcare */}

              {/* Start Entri Kunjungan Pcare */}
              <VmView
                // key={`${JSON.stringify({im})}${idxs}`}
                className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
              >
                <VmText className="text-slate-800 font-bold text-xl mb-3">
                  Kunjungan
                </VmText>
                {rdata?.kunpcarekunjungan ? (
                  <>
                    <VmView className="grid grid-cols-2 gap-2">
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'No. Kunjungan'}
                        </VmText>
                        <VmText className="text-slate-600 text-base text-break-word">
                          {rdata.kunpcarekunjungan
                            ? `${rdata.kunpcarekunjungan}`
                            : '-'}
                        </VmText>
                      </VmView>

                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Faskes Pelayanan'}
                        </VmText>
                        <VmText className="text-slate-600 text-base text-break-word">
                          {kdata?.pcaredata?.providerPelayanan?.kdProvider
                            ? `${kdata?.pcaredata?.providerPelayanan?.nmProvider} - (${kdata?.pcaredata?.providerPelayanan?.kdProvider})`
                            : '-'}
                        </VmText>
                      </VmView>
                    </VmView>
                  </>
                ) : (
                  <>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Faskes Pelayanan'}
                      </VmText>
                      <VmText className="text-slate-600 text-base text-break-word">
                        {kdata?.pcaredata?.providerPelayanan?.kdProvider
                          ? `${kdata?.pcaredata?.providerPelayanan?.nmProvider} - (${kdata?.pcaredata?.providerPelayanan?.kdProvider})`
                          : '-'}
                      </VmText>
                    </VmView>
                  </>
                )}
                {/* <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Faskes Pelayanan'}
                  </VmText>
                  <VmText className="text-slate-600 text-base text-break-word">
                    {kdata?.pcaredata?.providerPelayanan?.kdProvider
                      ? `${kdata?.pcaredata?.providerPelayanan?.nmProvider} - (${kdata?.pcaredata?.providerPelayanan?.kdProvider})`
                      : '-'}
                  </VmText>
                </VmView> */}
                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Perawatan'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.tkp?.kdTkp == 10
                        ? 'Rawat Jalan'
                        : kdata?.pcaredata?.tkp?.kdTkp == 20
                        ? 'Rawat Inap'
                        : kdata?.pcaredata?.tkp?.kdTkp == 50
                        ? 'Promotif Preventif'
                        : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Poli'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.poli?.kdPoli
                        ? `${kdata?.pcaredata?.poli?.kdPoli} - ${kdata?.pcaredata?.poli?.nmPoli}`
                        : '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Tanggal Kunjungan'}
                  </VmText>
                  <VmView className="w-full mt-2">
                    <DatePicker
                      locale={'id'}
                      selected={tglkunjunganpcare}
                      onChange={date => {
                        settglkunjunganpcare(date)
                        onChangeData({
                          tglkunjunganpcare: date,
                        })
                        // setPeriodeakhir(date)
                      }}
                      className="text-xs w-full"
                      wrapperClassName="w-full"
                      withPortal
                      customInput={
                        <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                          <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                          <VmView className="text-neutral-700 text-sm font-bold">
                            {tglDDMMMYYYY(tglkunjunganpcare)}
                          </VmView>
                        </VmView>
                      }
                      // maxDate={periodeakhir}
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={60}
                      scrollableYearDropdown
                      dropdownMode="select"
                    >
                      <VmView />
                    </DatePicker>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Keluhan'}
                  </VmText>
                  <VmInput
                    value={kdata?.keluhanutama || ''}
                    id="keluhanpcare"
                    label="keluhanpcare"
                    placeholder={"Isi Keluhan dari Subjective"}
                    disabled
                    typeInput={'textarea'}
                    className="w-full py-1.5 h-20 resize-none"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Anamnesa'}
                  </VmText>
                  <VmInput
                    value={anamnesa || ''}
                    id="anamnesa"
                    label="anamnesa"
                    onChange={e => {
                      setanamnesa(e.target.value)
                      onChangeData({anamnesa: e.target.value})
                    }}
                    typeInput={'textarea'}
                    className="w-full py-1.5 h-20 resize-none"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Alergi Makanan'}
                  </VmText>
                  <VmView className="self-stretch justify-start items-center gap-2 flex">
                    <VmView className="flex-1 relative">
                      <VmInput
                        label="select-alergimakanan"
                        // value={
                        //   alergimakanan?.id ? `${alergimakanan?.id} - ${alergimakanan?.name}` : ''
                        // }
                        value={alergimakanan || ''}
                        placeholder={"Pilih Alergi Makanan"}
                        typeInput={'text'}
                        className="w-full py-1.5 cursor-pointer"
                      />
                    </VmView>
                    <VmView
                      className="p-2 bg-red-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                      id='unselect-alergimakanan'
                      label="unselect-alergimakanan"
                      style={
                        alergimakanan ? {display: 'block'} : {display: 'none'}
                      }
                    >
                      <VmXCircleIcon
                        className="w-[24px] text-white"
                        aria-label="unselect-alergimakanan"
                      />
                    </VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Alergi Udara'}
                  </VmText>
                  <VmView className="self-stretch justify-start items-center gap-2 flex">
                    <VmView className="flex-1 relative">
                      <VmInput
                        label="select-alergiudara"
                        // value={
                        //   alergiudara?.id ? `${alergiudara?.id} - ${alergiudara?.name}` : ''
                        // }
                        value={alergiudara || ''}
                        placeholder={"Pilih Alergi Udara"}
                        typeInput={'text'}
                        className="w-full py-1.5 cursor-pointer"
                      />
                    </VmView>
                    <VmView
                      className="p-2 bg-red-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                      id='unselect-alergiudara'
                      label="unselect-alergiudara"
                      style={
                        alergiudara ? {display: 'block'} : {display: 'none'}
                      }
                    >
                      <VmXCircleIcon
                        className="w-[24px] text-white"
                        aria-label="unselect-alergiudara"
                      />
                    </VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Alergi Obat'}
                  </VmText>
                  <VmView className="self-stretch justify-start items-center gap-2 flex">
                    <VmView className="flex-1 relative">
                      <VmInput
                        label="select-alergiobat"
                        // value={
                        //   alergiobat?.id ? `${alergiobat?.id} - ${alergiobat?.name}` : ''
                        // }
                        value={alergiobat || ''}
                        placeholder={"Pilih Alergi Obat"}
                        typeInput={'text'}
                        className="w-full py-1.5 cursor-pointer"
                      />
                    </VmView>
                    <VmView
                      className="p-2 bg-red-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                      id='unselect-alergiobat'
                      label="unselect-alergiobat"
                      style={
                        alergiobat ? {display: 'block'} : {display: 'none'}
                      }
                    >
                      <VmXCircleIcon
                        className="w-[24px] text-white"
                        aria-label="unselect-alergiobat"
                      />
                    </VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Prognosa'}
                  </VmText>
                  <VmView className="self-stretch justify-start items-center gap-2 flex">
                    <VmView className="flex-1 relative">
                      <VmInput
                        label="select-prognosa"
                        // value={
                        //   prognosa?.id ? `${prognosa?.id} - ${prognosa?.name}` : ''
                        // }
                        value={prognosa || ''}
                        bgColor={prognosa ? 'bg-green-500' : 'bg-white'}
                        placeholder={"Pilih Prognosa"}
                        typeInput={'text'}
                        className="w-full py-1.5 cursor-pointer"
                        // style={{backgroundColor: prognosa ? '#3B82F6' : '#fff', color: prognosa ? '#fff' : '#111827'}}

                      />
                    </VmView>
                    <VmView
                      className="p-2 bg-red-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                      id='unselect-prognosa'
                      label="unselect-prognosa"
                      style={
                        prognosa ? {display: 'block'} : {display: 'none'}
                      }
                    >
                      <VmXCircleIcon
                        className="w-[24px] text-white"
                        aria-label="unselect-prognosa"
                      />
                    </VmView>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Terapi Obat'}
                  </VmText>
                  <VmInput
                    value={terapiobat}
                    id="terapiobat"
                    label="terapiobat"
                    placeholder={"Isi Terapi Obat dari Plan"}
                    disabled
                    typeInput={'textarea'}
                    className="w-full py-1.5 h-20 resize-none"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Terapi Non Obat'}
                  </VmText>
                  <VmInput
                    value={terapinonobat}
                    id="terapinonobat"
                    label="terapinonobat"
                    onChange={e => {
                      setterapinonobat(e.target.value)
                      onChangeData({terapinonobat: e.target.value})
                    }}
                    typeInput={'textarea'}
                    className="w-full py-1.5 h-20 resize-none"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'BMHP'}
                  </VmText>
                  <VmInput
                    value={bmhp}
                    id="bmhp"
                    label="bmhp"
                    onChange={e => {
                      setbmhp(e.target.value)
                      onChangeData({bmhp: e.target.value})
                    }}
                    typeInput={'textarea'}
                    className="w-full py-1.5 h-20 resize-none"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Diagnosa'}
                  </VmText>
                  <VmInput
                    value={kdata?.diagnosa ?? ''}
                    label="diagnosapcare"
                    placeholder={"Isi Diagnosa Utama dari Assessment"}
                    id="diagnosapcare"
                    disabled
                    typeInput={'text'}
                    className="w-full py-1.5 cursor-pointer"
                  />
                </VmView>
                {/* {kdata?.icdid && ( */}
                <>
                  <VmView
                    id="diagsekunderview"
                    className="flex flex-col"
                    style={
                      kdata?.icdid ? {display: 'block'} : {display: 'none'}
                    }
                  >
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Diagnosa #2 (sekunder)'}
                    </VmText>
                    <VmView className="self-stretch justify-start items-center gap-2 flex">
                      <VmView className="flex-1 relative">
                        <VmInput
                          value={kdata?.icdid ?? ''}
                          label="diagsekunderpcare"
                          placeholder={"Isi Diagnosa Sekunder dari Assessment"}
                          id="diagsekunderpcare"
                          disabled
                          typeInput={'text'}
                          className="w-full py-1.5 cursor-pointer"
                        />
                      </VmView>

                      <VmView
                        className="p-2 bg-blue-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                        label="view-diagnosa3"
                      >
                        <VmPlusCircleIcon
                          className="w-[24px] text-white"
                          aria-label="view-diagnosa3"
                        />
                        <VmText
                          className="text-white"
                          label="view-diagnosa3"
                        >
                          {'Diagnosa #3'}
                        </VmText>
                      </VmView>
                    </VmView>
                  </VmView>
                </>
                <>
                  <VmView
                    id="diagnosa3"
                    className="flex flex-col"
                    // style={{display:'none'}}
                    style={
                      diagnosa3 ? {display: 'block'} : {display: 'none'}
                    }
                  >
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Diagnosa #3 (sekunder)'}
                    </VmText>
                    <VmView className="self-stretch justify-start items-center gap-2 flex">
                      <VmView className="flex-1 relative">
                        <VmInput
                          label="select-diagnosa3"
                          id="diag3sekunderpcare"
                          value={diagnosa3}
                          onChange={e => {
                            setdiagnosa3(e.target.value)
                            onChangeData({
                              diagnosa3: e.target.value,
                            })
                          }}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              onClickData(e)
                            }
                          }}
                          placeholder={'Cari Diagnosa : Ketik Minimal 3 Karakter Lalu Enter'}
                          typeInput={'text'}
                          className="w-full py-1.5 cursor-pointer"
                        />
                      </VmView>
                      <VmView
                        className="p-2 bg-blue-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                        label="select-search-diagnosa3"
                      >
                        <VmMagnifyingGlassCircleIcon
                          className="w-[24px] text-white"
                          aria-label="select-search-diagnosa3"
                        />
                      </VmView>
                      <VmView
                        className="p-2 bg-red-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                        label="unselect-diagnosa3"
                      >
                        <VmXCircleIcon
                          className="w-[24px] text-white"
                          aria-label="unselect-diagnosa3"
                        />
                      </VmView>
                    </VmView>
                  </VmView>
                </>
                {/* )} */}

                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Kesadaran'}
                  </VmText>
                  <VmInput
                    // onChange={e => setkesadaran(e.target.value)}
                    label="select-kesadaran"
                    value={
                      kesadaran?.id
                        ? `${kesadaran?.id} - ${kesadaran?.name}`
                        : ''
                    }
                    typeInput={'text'}
                    className="w-full py-1.5 cursor-pointer"
                  />
                </VmView>

                <VmView className="flex flex-col mt-2 mb-2">
                  <VmText className="text-slate-600 font-bold text-lg">
                    {'Pemeriksaan Fisik'}
                  </VmText>
                </VmView>

                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Tinggi Badan'}
                    </VmText>
                    <VmText
                      id="tbpcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.tinggi ? `${kdata?.tinggi} cm` : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Berat Badan'}
                    </VmText>
                    <VmText
                      id="bbpcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.berat ? `${kdata?.berat} kg` : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Lingkar Perut'}
                    </VmText>
                    <VmText 
                      id="lppcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.perut ? `${kdata?.perut} cm` : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'IMT'}
                    </VmText>
                    <VmText className="text-slate-600 text-base text-break-word">
                      {kdata?.pcaredata?.IMT
                        ? `${kdata?.pcaredata?.IMT} kg/m2`
                        : '-'}
                    </VmText>
                  </VmView>
                </VmView>

                <VmView className="flex flex-col mt-2 mb-2">
                  <VmText className="text-slate-600 font-bold text-lg">
                    {'Tekanan Darah'}
                  </VmText>
                </VmView>

                <VmView className="grid grid-cols-2 gap-2">
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Sistole'}
                    </VmText>
                    <VmText
                      id="sistolepcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.tekanandarah_systolic
                        ? `${kdata?.tekanandarah_systolic} mmHg`
                        : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Diastole'}
                    </VmText>
                    <VmText
                      id="diastolepcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.tekanandarah_diastolic
                        ? `${kdata?.tekanandarah_diastolic} mmHg`
                        : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Heart Rate'}
                    </VmText>
                    <VmText
                      id="heartratepcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.nadi ? `${kdata?.nadi} bpm` : '-'}
                    </VmText>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Respiratory Rate'}
                    </VmText>
                    <VmText
                      id="respratepcare"
                      className="text-slate-600 text-base text-break-word"
                    >
                      {kdata?.nafas ? `${kdata?.nafas} /minute` : '-'}
                    </VmText>
                  </VmView>
                </VmView>
                <VmView className="flex flex-col mt-2">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Tenaga Medis'}
                  </VmText>
                  <VmInput
                    label="select-dokter"
                    value={
                      tenagamedis?.id
                        ? `${tenagamedis?.id} - ${tenagamedis?.name}`
                        : ''
                    }
                    typeInput={'text'}
                    className="w-full py-1.5"
                  />
                </VmView>

                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Status Pulang'}
                  </VmText>
                  <VmInput
                    label="select-statuspulang"
                    value={
                      statuspulang?.id
                        ? `${statuspulang?.id} - ${statuspulang?.name}`
                        : ''
                    }
                    typeInput={'text'}
                    className="w-full py-1.5"
                  />
                </VmView>
                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'Tanggal Pulang'}
                  </VmText>
                  <VmView className="w-full mt-2">
                    <DatePicker
                      locale={'id'}
                      selected={tglpulangpcare}
                      onChange={date => {
                        settglpulangpcare(date)
                        onChangeData({
                          tglpulangpcare: date,
                          pcaretglpulang: moment(date)
                            .utc()
                            .format('YYYY-MM-DD'),
                        })
                        // setPeriodeakhir(date)
                      }}
                      className="text-xs w-full"
                      wrapperClassName="w-full"
                      withPortal
                      customInput={
                        <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                          <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                          <VmView className="text-neutral-700 text-sm font-bold">
                            {tglDDMMMYYYY(tglpulangpcare)}
                          </VmView>
                        </VmView>
                      }
                      // maxDate={periodeakhir}
                      minDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={60}
                      scrollableYearDropdown
                      dropdownMode="select"
                    >
                      <VmView />
                    </DatePicker>
                  </VmView>
                </VmView>

                {statuspulang?.name?.toLowerCase()?.includes('rujuk') && (
                  <>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Jenis Rujukan'}
                      </VmText>
                      <div className="col-md-9 mt-2">
                        <div className="custom-radio">
                          {listJenisRujukan.map(value => (
                            <label
                              key={value}
                              style={{fontWeight: 'unset'}}
                              className="mr-4"
                            >
                              <input
                                type="radio"
                                className="input_radio_jenis_rujukan"
                                value={value}
                                name="jenisRujukan"
                                style={{marginRight: '5px'}}
                                checked={jenisRujukan === value}
                                onChange={handleJenisRujukan}
                              />
                              {value === 1 ? 'Kondisi Khusus' : 'Spesialis'}
                            </label>
                          ))}
                        </div>
                      </div>
                    </VmView>
                  </>
                )}

                {statuspulang?.name?.toLowerCase()?.includes('rujuk') &&
                  jenisRujukan == 2 && (
                    <>
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Spesialis'}
                        </VmText>
                        <VmInput
                          // required={jenisRujukan == 2 ? true : false}
                          label="select-spesialis"
                          value={
                            spesialis?.id
                              ? `${spesialis?.id} - ${spesialis?.name}`
                              : ''
                          }
                          typeInput={'text'}
                          className="w-full py-1.5"
                        />
                      </VmView>
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Sub Spesialis'}
                        </VmText>
                        <VmInput
                          // required={jenisRujukan == 2 ? true : false}
                          label="select-subspesialis"
                          value={
                            subspesialis?.id
                              ? `${subspesialis?.id} - ${subspesialis?.name}`
                              : ''
                          }
                          typeInput={'text'}
                          className="w-full py-1.5"
                        />
                      </VmView>
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Sarana'}
                        </VmText>
                        <VmInput
                          label="select-sarana"
                          value={
                            sarana?.id ? `${sarana?.id} - ${sarana?.name}` : ''
                          }
                          typeInput={'text'}
                          className="w-full py-1.5 cursor-pointer"
                        />
                      </VmView>
                    </>
                  )}

                {statuspulang?.name?.toLowerCase()?.includes('rujuk') &&
                  jenisRujukan == 1 && (
                    <>
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Kategori'}
                        </VmText>
                        <VmInput
                          // required={jenisRujukan == 1 ? true : false}
                          label="select-khusus"
                          value={
                            khusus?.id ? `${khusus?.id} - ${khusus?.name}` : ''
                          }
                          typeInput={'text'}
                          className="w-full py-1.5 cursor-pointer"
                        />
                      </VmView>
                      <VmView className="flex flex-col">
                        <VmText className="text-slate-600 font-bold text-base">
                          {'Alasan'}
                        </VmText>
                        <VmInput
                          // required={jenisRujukan == 1 ? true : false}
                          label="alasan"
                          onChange={e => {
                            setcatatan(e.target.value)
                            onChangeData({catatan: e.target.value})
                          }}
                          value={catatan}
                          typeInput={'textarea'}
                          className="w-full py-1.5 h-20 resize-none"
                        />
                      </VmView>
                    </>
                  )}

                {statuspulang?.name?.toLowerCase()?.includes('rujuk') && (
                  <>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Tanggal Rencana Berkunjung'}
                      </VmText>
                      <VmView className="self-stretch justify-start items-center gap-2 flex">
                        <VmView className="flex-1 relative">
                          <DatePicker
                            locale={'id'}
                            selected={tglberkunjungpcare}
                            onChange={date => {
                              settglberkunjungpcare(date)
                              onChangeData({
                                tglberkunjungpcare: date,
                              })
                              // setPeriodeakhir(date)
                            }}
                            className="text-xs w-full"
                            wrapperClassName="w-full"
                            withPortal
                            customInput={
                              <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                                <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                                <VmView className="text-neutral-700 text-sm font-bold">
                                  {tglDDMMMYYYY(tglberkunjungpcare)}
                                </VmView>
                              </VmView>
                            }
                            // maxDate={periodeakhir}
                            minDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={60}
                            scrollableYearDropdown
                            dropdownMode="select"
                          >
                            <VmView />
                          </DatePicker>
                        </VmView>

                        <VmView
                          className="p-2 bg-blue-500 rounded-xl justify-center items-start gap-2.5 flex cursor-pointer"
                          label="select-faskes"
                          onClick={e => onClickData(e)}
                        >
                          <VmMagnifyingGlassIcon
                            className="w-[24px] text-white"
                            aria-label="select-faskes"
                            onClick={e => onClickData(e)}
                          />
                          <VmText
                            className="text-white"
                            label="select-faskes"
                            onClick={e => onClickData(e)}
                          >
                            {'Cari Faskes'}
                          </VmText>
                        </VmView>
                      </VmView>
                    </VmView>

                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Faskes Rujukan'}
                      </VmText>
                      <VmText className="text-slate-600 text-base text-break-word">
                        {faskesrujukan?.kdppk
                          ? `${faskesrujukan?.nmppk} (${faskesrujukan?.kdppk})`
                          : '-'}
                      </VmText>
                    </VmView>
                  </>
                )}

                <VmView className="flex flex-col">
                  <VmText className="text-slate-600 font-bold text-base">
                    {'TACC'}
                  </VmText>
                  <VmInput
                    label="select-tacc"
                    value={tacc?.id ? `${tacc?.id} - ${tacc?.name}` : ''}
                    typeInput={'text'}
                    className="w-full py-1.5"
                  />
                </VmView>

                {tacc?.id && tacc?.id != '-1' && (
                  <>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Alasan TACC'}
                      </VmText>
                      <VmInput
                        disabled={tacc?.id == '3' ? true : false}
                        label="select-alasantacc"
                        value={
                          alasantacc?.id
                            ? `${alasantacc?.id} - ${alasantacc?.name}`
                            : ''
                        }
                        typeInput={'text'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </>
                )}
              </VmView>
              {/* End Entri Kunjungan Pcare */}

              {/* Start Entri Tindakan Pcare */}
              <VmView
                // key={`${JSON.stringify({im})}${idxs}`}
                className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
              >
                <VmText className="text-slate-800 font-bold text-xl mb-3">
                  Tindakan
                </VmText>

                {tindakanMerged.length === 0 ? (
                  <VmText className="text-slate-600 font-bold text-base">
                    Anda belum melakukan tindakan atau tindakan belum disinkronisasi.
                  </VmText>
                ) : (
                  tindakanMerged.map((item, idx) => {
                    return (
                      <VmView
                        key={`tindakan${idx}`}
                        className={`flex flex-col border border-slate-300 rounded-lg p-4 ${idx != 0 ? 'mt-2' : ''}`}
                      >
                        <VmText className="text-slate-600 font-bold text-base">
                          {item.biakodebpjs}
                        </VmText>
                        <VmText className="text-slate-600 text-base text-break-word">
                          {item.biaicdkode ? item.biaicdkode + ' - ' : ''}{' '}
                          {item.bianama}
                        </VmText>
                        <VmView className="flex justify-between items-center">
                          <VmText
                            className={`text-slate-600 text-base text-break-word ${item.status === 'Paid' ? 'text-green-600' : 'text-red-600'}`}
                          >
                            Status: {item.status === 'Paid' ? 'Sudah dibayar' : 'Belum dibayar'}
                          </VmText>
                          {item.status === 'Unpaid' && (
                            // <VmTrashIcon
                            //   className="w-[24px] text-red-600"
                            //   // aria-label="unpaid-status"
                            //   onClick={e => onDeleteTindakan(e, item)}
                            // />
                            <VmButton
                              onClick={e => onDeleteTindakan(e, item, rdata?.kunpcarekunjungan)}
                              // className="btn btn-success w-fit h-10"
                              color='danger'
                              className="btn btn-danger w-fit"
                              label="unpaid-status"
                            >
                            {'Hapus'}
                            </VmButton>
                          )}
                        </VmView>
                      </VmView>
                    );
                  })
                )}
              </VmView>
              {/* End Entri Tindakan Pcare */}

              {/* Start Entri Obat Pcare */}
              {rdata?.kunpcarekunjungan && (
                <VmView className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3">
                  <VmText className="text-slate-800 font-bold text-xl mb-3">
                    Obat
                  </VmText>
                  {!disabled && (
                    <VmView className="flex justify-between">
                      <VmText className="text-slate-600 font-bold text-base place-self-center">
                        {'Pilih Obat'}
                      </VmText>
                      <VmButton className="w-fit" label="btn-add-pcare-obat">
                        Tambah Obat
                      </VmButton>
                    </VmView>
                  )}
                  {carts?.length > 0 ? (
                    <VmView className="flex flex-col bg-gray-100 rounded-xl p-4">
                      {carts.map((im, index) => {
                        if(im?.kdObatSK){
                          return (
                            <CardItem
                              label="-obat"
                              key={`CardItemPc-${index}`}
                              index={index}
                              data={im}
                              disabled={disabled}
                              showadd={false}
                            />
                          )
                        }
                      })}
                    </VmView>
                  ) : (
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Tidak memiliki data obat.'}
                    </VmText>
                  )}
                </VmView>
              )}
              {/* End Entri Obat Pcare */}

              {/* Start Entri MCU Pcare */}
              {rdata?.kunpcarekunjungan && (
                <>
                  <VmView className="flex justify-center items-center">
                    <VmButton
                      label="hide-mcu"
                      rounded="full"
                      className="btn btn-info w-56"
                    >
                      {headmcu[0]}
                    </VmButton>
                  </VmView>
                  <VmView className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3">
                  <VmText className="text-slate-800 font-bold text-xl mb-3">
                    MCU
                  </VmText>

                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Kode MCU'}
                    </VmText>
                    <VmInput
                      label="kodeMcu"
                      value={kdMCU}
                      onChange={e => {
                        setkdMCU(e.target.value)
                        onChangeData({kdMCU: e.target.value})
                      }}
                      typeInput={'number'}
                      disabled
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'No Kunjungan'}
                    </VmText>
                    <VmInput
                      label="noKunjungan"
                      value={rdata?.kunpcarekunjungan || noKunjungan}
                      // onChange={e => {
                      //   setnoKunjungan(e.target.value)
                      //   onChangeData({noKunjungan: e.target.value})
                      // }}
                      typeInput={'text'}
                      disabled
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Kode Provider'}
                    </VmText>
                    <VmInput
                      label="kdProvider"
                      value={
                        kdata?.pcaredata?.providerPelayanan?.kdProvider ||
                        kdProvider
                      }
                      // onChange={e => {
                      //   setkdProvider(e.target.value)
                      //   onChangeData({kdProvider: e.target.value})
                      // }}
                      typeInput={'text'}
                      disabled
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Tanggal Pelayanan'}
                    </VmText>
                    <VmView className="w-full mt-2">
                      <DatePicker
                        locale={'id'}
                        selected={tglPelayanan}
                        onChange={date => {
                          settglPelayanan(date)
                          onChangeData({tglPelayanan: date})
                          // setPeriodeakhir(date)
                        }}
                        className="text-xs w-full"
                        wrapperClassName="w-full"
                        withPortal
                        customInput={
                          <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                            <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                            <VmView className="text-neutral-700 text-sm font-bold">
                              {tglDDMMMYYYY(tglPelayanan)}
                            </VmView>
                          </VmView>
                        }
                        // maxDate={periodeakhir}
                        minDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={60}
                        scrollableYearDropdown
                        dropdownMode="select"
                      >
                        <VmView />
                      </DatePicker>
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Tekanan Darah'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Tekanan Darah Sistole'}
                      </VmText>
                      <VmInput
                        label="tekananDarahSistole"
                        value={tekananDarahSistole}
                        onChange={e => {
                          const inputValue = e.target.value;
                          if (!inputValue.includes('-') || !inputValue.toLowerCase().includes('e')) {
                            settekananDarahSistole(e.target.value)
                            onChangeData({tekananDarahSistole: e.target.value})
                          }
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Tekanan Darah Diastole'}
                      </VmText>
                      <VmInput
                        label="tekananDarahDiastole"
                        value={tekananDarahDiastole}
                        onChange={e => {
                          settekananDarahDiastole(e.target.value)
                          onChangeData({tekananDarahDiastole: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Foto Radiologi'}
                    </VmText>
                    <VmView className="self-stretch justify-start items-center gap-2 flex">
                      <VmView className="flex-1 relative">
                        <VmInput
                          label="radiologiFoto"
                          ref={fileInputRef}
                          value={radiologiFoto}
                          onChange={e => {
                            const files = e.target.value
                            setradiologiFoto(files)
                            onChangeData({radiologiFoto: files})
                          }}
                          typeInput={'file'}
                          className="w-full py-1.5"
                        />
                      </VmView>
                      <VmButton
                        onClick={handleButtonClick}
                        className="w-fit h-10"
                        label="btnradiologiFoto"
                      >
                        Pilih Gambar
                      </VmButton>
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Darah Rutin'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Hemoglobin'}
                      </VmText>
                      <VmInput
                        label="darahRutinHemo"
                        value={darahRutinHemo}
                        onChange={e => {
                          setdarahRutinHemo(e.target.value)
                          onChangeData({darahRutinHemo: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Leukosit'}
                      </VmText>
                      <VmInput
                        label="darahRutinLeu"
                        value={darahRutinLeu}
                        onChange={e => {
                          setdarahRutinLeu(e.target.value)
                          onChangeData({darahRutinLeu: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Eritrosit'}
                      </VmText>
                      <VmInput
                        label="darahRutinErit"
                        value={darahRutinErit}
                        onChange={e => {
                          setdarahRutinErit(e.target.value)
                          onChangeData({darahRutinErit: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Rutin Laju'}
                      </VmText>
                      <VmInput
                        label="darahRutinLaju"
                        value={darahRutinLaju}
                        onChange={e => {
                          setdarahRutinLaju(e.target.value)
                          onChangeData({darahRutinLaju: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Hematrosit'}
                      </VmText>
                      <VmInput
                        label="darahRutinHema"
                        value={darahRutinHema}
                        onChange={e => {
                          setdarahRutinHema(e.target.value)
                          onChangeData({darahRutinHema: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Darah Rutin Trombosit'}
                      </VmText>
                      <VmInput
                        label="darahRutinTrom"
                        value={darahRutinTrom}
                        onChange={e => {
                          setdarahRutinTrom(e.target.value)
                          onChangeData({darahRutinTrom: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Lemak Darah'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Lemak Darah HDL'}
                      </VmText>
                      <VmInput
                        label="lemakDarahHDL"
                        value={lemakDarahHDL}
                        onChange={e => {
                          setlemakDarahHDL(e.target.value)
                          onChangeData({lemakDarahHDL: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Lemak Darah LDL'}
                      </VmText>
                      <VmInput
                        label="lemakDarahLDL"
                        value={lemakDarahLDL}
                        onChange={e => {
                          setlemakDarahLDL(e.target.value)
                          onChangeData({lemakDarahLDL: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Lemak Darah Chol'}
                      </VmText>
                      <VmInput
                        label="lemakDarahChol"
                        value={lemakDarahChol}
                        onChange={e => {
                          setlemakDarahChol(e.target.value)
                          onChangeData({lemakDarahChol: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Lemak Darah Trigliserida'}
                      </VmText>
                      <VmInput
                        label="lemakDarahTrigli"
                        value={lemakDarahTrigli}
                        onChange={e => {
                          setlemakDarahTrigli(e.target.value)
                          onChangeData({lemakDarahTrigli: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Gula Darah'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Gula Darah Sewaktu'}
                      </VmText>
                      <VmInput
                        label="gulaDarahSewaktu"
                        value={gulaDarahSewaktu}
                        onChange={e => {
                          setgulaDarahSewaktu(e.target.value)
                          onChangeData({gulaDarahSewaktu: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Gula Darah Puasa'}
                      </VmText>
                      <VmInput
                        label="gulaDarahPuasa"
                        value={gulaDarahPuasa}
                        onChange={e => {
                          setgulaDarahPuasa(e.target.value)
                          onChangeData({gulaDarahPuasa: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Gula Darah Post Prandial'}
                      </VmText>
                      <VmInput
                        label="gulaDarahPostPrandial"
                        value={gulaDarahPostPrandial}
                        onChange={e => {
                          setgulaDarahPostPrandial(e.target.value)
                          onChangeData({gulaDarahPostPrandial: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Gula Darah HbA1c'}
                      </VmText>
                      <VmInput
                        label="gulaDarahHbA1c"
                        value={gulaDarahHbA1c}
                        onChange={e => {
                          setgulaDarahHbA1c(e.target.value)
                          onChangeData({gulaDarahHbA1c: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Fungsi Hati'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Hati SGOT'}
                      </VmText>
                      <VmInput
                        label="fungsiHatiSGOT"
                        value={fungsiHatiSGOT}
                        onChange={e => {
                          setfungsiHatiSGOT(e.target.value)
                          onChangeData({fungsiHatiSGOT: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Hati SGPT'}
                      </VmText>
                      <VmInput
                        label="fungsiHatiSGPT"
                        value={fungsiHatiSGPT}
                        onChange={e => {
                          setfungsiHatiSGPT(e.target.value)
                          onChangeData({fungsiHatiSGPT: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Hati Gamma'}
                      </VmText>
                      <VmInput
                        label="fungsiHatiGamma"
                        value={fungsiHatiGamma}
                        onChange={e => {
                          setfungsiHatiGamma(e.target.value)
                          onChangeData({fungsiHatiGamma: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Hati Protkual'}
                      </VmText>
                      <VmInput
                        label="fungsiHatiProtKual"
                        value={fungsiHatiProtKual}
                        onChange={e => {
                          setfungsiHatiProtKual(e.target.value)
                          onChangeData({fungsiHatiProtKual: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Fungsi Hati Albumin'}
                    </VmText>
                    <VmInput
                      label="fungsiHatiAlbumin"
                      value={fungsiHatiAlbumin}
                      onChange={e => {
                        setfungsiHatiAlbumin(e.target.value)
                        onChangeData({fungsiHatiAlbumin: e.target.value})
                      }}
                      typeInput={'number'}
                      className="w-full py-1.5"
                    />
                  </VmView>

                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Fungsi Ginjal'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Ginjal Crea'}
                      </VmText>
                      <VmInput
                        label="fungsiGinjalCrea"
                        value={fungsiGinjalCrea}
                        onChange={e => {
                          setfungsiGinjalCrea(e.target.value)
                          onChangeData({fungsiGinjalCrea: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Ginjal Ureum'}
                      </VmText>
                      <VmInput
                        label="fungsiGinjalUreum"
                        value={fungsiGinjalUreum}
                        onChange={e => {
                          setfungsiGinjalUreum(e.target.value)
                          onChangeData({fungsiGinjalUreum: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Fungsi Ginjal Asam'}
                    </VmText>
                    <VmInput
                      label="fungsiGinjalAsam"
                      value={fungsiGinjalAsam}
                      onChange={e => {
                        setfungsiGinjalAsam(e.target.value)
                        onChangeData({fungsiGinjalAsam: e.target.value})
                      }}
                      typeInput={'number'}
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col mt-2 mb-2">
                    <VmText className="text-slate-600 font-bold text-lg">
                      {'Fungsi Jantung'}
                    </VmText>
                  </VmView>
                  <VmView className="grid grid-cols-2 gap-2">
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Jantung ABI'}
                      </VmText>
                      <VmInput
                        label="fungsiJantungABI"
                        value={fungsiJantungABI}
                        onChange={e => {
                          setfungsiJantungABI(e.target.value)
                          onChangeData({fungsiJantungABI: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                    <VmView className="flex flex-col">
                      <VmText className="text-slate-600 font-bold text-base">
                        {'Fungsi Jantung EKG'}
                      </VmText>
                      <VmInput
                        label="fungsiJantungEKG"
                        value={fungsiJantungEKG}
                        onChange={e => {
                          setfungsiJantungEKG(e.target.value)
                          onChangeData({fungsiJantungEKG: e.target.value})
                        }}
                        typeInput={'number'}
                        className="w-full py-1.5"
                      />
                    </VmView>
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Fungsi Jantung Echo'}
                    </VmText>
                    <VmInput
                      label="fungsiJantungEcho"
                      value={fungsiJantungEcho}
                      onChange={e => {
                        setfungsiJantungEcho(e.target.value)
                        onChangeData({fungsiJantungEcho: e.target.value})
                      }}
                      typeInput={'input'}
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Funduskopi'}
                    </VmText>
                    <VmInput
                      label="funduskopi"
                      value={funduskopi}
                      onChange={e => {
                        setfunduskopi(e.target.value)
                        onChangeData({funduskopi: e.target.value})
                      }}
                      typeInput={'input'}
                      className="w-full py-1.5"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Pemeriksaan Lain'}
                    </VmText>
                    <VmInput
                      label="pemeriksaanLain"
                      value={pemeriksaanLain}
                      onChange={e => {
                        setpemeriksaanLain(e.target.value)
                        onChangeData({pemeriksaanLain: e.target.value})
                      }}
                      typeInput={'textarea'}
                      className="w-full py-1.5 h-20 resize-none"
                    />
                  </VmView>
                  <VmView className="flex flex-col">
                    <VmText className="text-slate-600 font-bold text-base">
                      {'Keterangan'}
                    </VmText>
                    <VmInput
                      label="keterangan"
                      value={keterangan}
                      onChange={e => {
                        setketerangan(e.target.value)
                        onChangeData({keterangan: e.target.value})
                      }}
                      typeInput={'textarea'}
                      className="w-full py-1.5 h-20 resize-none"
                    />
                  </VmView>

                  <VmView className="flex justify-center items-center">
                    <VmButton
                      label="save-mcu"
                      rounded="full"
                      color='success'
                      className="btn btn-success w-56"
                    >
                      {'Simpan Entri MCU'}
                    </VmButton>
                  </VmView>
                </VmView>
                </>
              )}
              {/* End  Entri MCU Pcare */}
            </Fragment>
          </VmView>
        </VmView>
      )}
      <VmModal
        ref={modalRef}
        position={modalposition}
        // onClose={onCloseModal}
      />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
    </Fragment>
  )
}
