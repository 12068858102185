import {
  useCallback,
  createContext,
  useState,
  Fragment,
  useEffect,
  createRef,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react'
import axios from 'axios'
import QueryString from 'qs'
import {TemplateForm} from 'template'
import {rgbcolors} from 'config/colors'
import {isFunction, isObject, isString} from 'utils'
import {FullErrorView, VmCartItem, VmInputHandler, VmWrapCart} from 'molecules'
import {
  VmButton,
  VmDataTable,
  VmIcons,
  VmInput,
  VmSpinner,
  VmText,
  VmView,
} from 'components'
import moment from 'moment'
import ModalPembayaran from './ModalPembayaran'
import ModalPencarian from './ModalPencarian'
import {tglYMD, toCurrency, triggerEventBus} from 'utils/functions'
import classNames from 'classnames'
import {mDetailVisit} from 'pages/prd-penjualan-obat-resep/api'
import {DataVisit} from './classes'

const {REACT_APP_BASE_URL_DOKTER: BASE_URL, REACT_APP_BASE_URL_BPJS: APIBPJS} =
  process.env
export const Context = createContext({})
const emptycart = (
  <VmView className="EmptyCart">
    <VmText>Anda belum memilih pemeriksaan</VmText>
    <VmText>Pemeriksaan Kosong</VmText>
    <VmView className="flex items-center justify-center">
      <VmIcons
        size={122}
        name="VmSlimeIcon"
        variant="abstract"
        color="var(--color-blue1-payment)"
      />
      <VmIcons
        size={90}
        name="VmCartIcon"
        className="absolute"
        color="var(--color-blue1-payment)"
      />
    </VmView>
  </VmView>
)
const FormPembayaran = ({
  pathname = '',
  controllers = (event, navigate) => {},
  navigate = () => {},
  queryParams = () => {},
  params = {},
  pageTitle = null,
}) => {
  // Pembayaran Pemeriksaan
  /**
   * value dari mode
   * phone = Mobile
   * web = Tablet
   * desktop = Praktik Versi Desktop
   */
  const [mode] = useState(params.mode)
  const tFormRef = createRef()
  const modalPaymentRef = createRef()
  const modalSearchRef = createRef()
  const headerRef = useRef()
  const timeout = useRef()
  const labelForm = 'FormPembayaranMobile'
  const [searchcost, setsearchcost] = useState('')
  const [prevsearchcost, setprevsearchcost] = useState('')
  const [smodasearch, setsmodasearch] = useState(Date.now())
  const [data, setdata] = useState(null)
  const [pdata, setpdata] = useState(null)
  const [items, setitems] = useState([])
  const [cart, setcart] = useState([])
  const [loadlimititems] = useState(50)
  const [loadoffsetitems, setloadoffsetitems] = useState(0)
  const [loadmoreitems, setloadmoreitems] = useState(true)
  const [onloadmoreitems, setonloadmoreitems] = useState(false)
  const [loaderroritems, setloaderroritems] = useState(false)
  const [loadinginitial, setloadinginitial] = useState(true)
  const [errorinitial, seterrorinitial] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [dctid] = useState(parseInt(params.id))
  const [kid] = useState(parseInt(queryParams.get('kid')))
  const [kunpcarekunjungan] = useState(queryParams.get('kunpcarekunjungan'))
  const [layout, setlayout] = useState({})
  const [customPcare, setCustomPcare] = useState(false)
  const showlog = true

  const onFetchVisit = () => {
    console.log('test', 'test')
    mDetailVisit({
      input: {
        uid: dctid,
        kid: kid,
        'show-error-log': `${showlog}`.toUpperCase(),
      },
    })
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          setpdata(new DataVisit(resdata?.data?.defaultdata))
          if (kunpcarekunjungan) {
            onFetchTindakan({kunpcarekunjungan: kunpcarekunjungan})
          }
        }
        if (showlog) console.log('onFetchPasien:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchPasien:ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('onFetchPasien:final')
      })
  }
  const onFetchTindakan = data => {
    var uid = dctid
    setLoading(true)
    axios
      .post(
        `${APIBPJS}/bpjs-jkn/tindakan-pcare`,
        QueryString.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: 'tindakan',
          method: 'GET',
          data: {
            noKunjungan: data?.kunpcarekunjungan,
          },
        })
      )
      .then(({data: resdata = {}}) => {
        if (resdata?.metaData?.code == 200) {
          var tindakan = resdata?.response?.list
          var biakodebpjs = tindakan.map(item => item.kdTindakan).join(',')

          // console.error('tindakan', tindakan)
          // console.error('biakodebpjs', biakodebpjs)

          axios
            .post(
              `${BASE_URL}/prd-lap-rekammedis/get-tindakan-pcare`,
              QueryString.stringify({
                a: 'dk13',
                uids: [0, dctid],
                biakodebpjs,
              })
            )
            .then(({data: res = {}}) => {
              // console.error('res', res)
              var newdata = res
              newdata?.map((im, index) => {
                var incart = cart.findIndex(fn => fn.id === im?.biaid)
                // console.error('incart', incart)
                if (incart >= 0) {
                  var newcart = cart
                  newcart[incart]['value'] += 1
                  setcart([...newcart])
                } else {
                  var kdTindakanSK = tindakan.find(
                    item => item.kdTindakan == im?.biakodebpjs
                  )?.kdTindakanSK

                  newdata[incart + 1]['kdtindakansk'] = kdTindakanSK
                  newdata[incart + 1]['id'] = im?.biaid
                  newdata[incart + 1]['name'] = im?.bianama
                  newdata[incart + 1]['price'] = parseFloat(im?.biatarif)
                  newdata[incart + 1]['disc'] = 0
                  newdata[incart + 1]['nominaldisc'] = 0
                  newdata[incart + 1]['value'] = 1
                  setcart([...cart, ...newdata])
                }

                // console.error('newcart onfetch', newcart)
              })
            })
            .catch(ch => {
              setLoading(false)
            })
            .finally(() => {
              setLoading(false)
            })
          setLoading(false)
          // setpdata(new DataVisit(resdata?.data?.defaultdata))
        }
        if (showlog) console.log('onFetchTindakan:data', resdata)
      })
      .catch(ch => {
        if (showlog) console.log('onFetchTindakan:ch', ch)
        setLoading(false)
      })
      .finally(() => {
        if (showlog) console.log('onFetchTindakan:final')
        setLoading(false)
      })
  }
  const onItemModalSearchClick = (data, tFormRef) => {
    var error, errorMsg
    try {
      modalSearchRef.current.close()
      const {id, pid} = data
      if (
        parseInt(id) === parseInt(pdata?.kid) &&
        parseInt(pid) === parseInt(pdata?.pid)
      ) {
        return true
      } else {
        setpdata(data)  
        if (customPcare && data?.kunpcarekunjungan) {
          onFetchTindakan(data)
        }

        setlayout({
          ...layout,
          header: {
            height: headerRef.current?.clientHeight || 0,
            width: headerRef.current?.clientWidth || 0,
          },
        })
      }
    } catch (error) {
      // console.error('error', error)
      setLoading(!true)
      // When error on try controller and data
    }
  }
  const onChangeData = useCallback(
    e => {
      if (!loadinginitial) {
        setdata(t => {
          if (isObject(e)) {
            return Object.assign(t, e)
          }
        })
      }
    },
    // eslint-disable-next-line
    [data, loadinginitial]
  )
  const onRequired = e => {
    return false
  }
  const parseItem = item => {
    return {
      id: item.biaid,
      name: item.bianama,
      price: parseInt(item.biatarif),
      disc: parseFloat(item.biadiskon),
      nominaldisc: parseInt(
        (parseInt(item.biatarif) * parseFloat(item.biadiskon)) / 100
      ),
      ...item,
    }
  }
  const onLoadCost = (limit = 10, offset = 0, cari = searchcost) => {
    const finallyproses = () => {
      setLoading(false)
      setonloadmoreitems(false)
    }
    setloaderroritems(false)
    return axios
      .post(
        `${BASE_URL}/biaya-v2/index`,
        QueryString.stringify({
          a: 'dk13',
          uids: [0, dctid],
          limit,
          offset,
          biaaktif: 1,
          cari,
        })
      )
      .then(({data: res}) => {
        if (res?.length > 0) {
          if (offset === 0) {
            setprevsearchcost(cari)
            setitems([...res.map(im => parseItem(im))])
          } else {
            setitems([...items, ...res.map(im => parseItem(im))])
          }
          setloadoffsetitems(offset + res.length)
        } else {
          if (offset === 0) {
            // message: `Tidak ada Item dengan pencarian (${cari})!`,
            setprevsearchcost(cari)
            setitems([])
          }
          setloadmoreitems(false)
        }

        if (res.length < limit && loadmoreitems) {
          setloadmoreitems(false)
        }
      })
      .catch(err => {
        // console.log('Error get Data Biaya Pemeriksaan', err.message)
        setloaderroritems(true)
      })
      .finally(finallyproses)
  }
  // const etbodytablecost = document.getElementById('tbody-table-cost')
  const onEndReachedCost = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (
      isBottom &&
      loadmoreitems &&
      !onloadmoreitems &&
      isString(searchcost) &&
      searchcost?.length > 0
    ) {
      setonloadmoreitems(true)
      return onLoadCost(loadlimititems, loadoffsetitems, searchcost)
    }
  }
  const onScrollCapture = e => {
    const {ariaLabel, value, id, children} = e.target
    if (id === 'content-table-cost') {
      return onEndReachedCost(children[0]?.children[1])
    }
  }
  const onKeyDown = (e, formRef) => {
    const {ariaLabel, value} = e.target
    if (ariaLabel === 'searchcost' && e.key === 'Enter') {
      if (isFunction(e.target?.blur)) {
        e.target.blur()
      }
    }
  }
  const onChangeCapture = (e, value) => {
    const {ariaLabel} = e.target
    // console.log('ariaLabel', ariaLabel)
    if (ariaLabel === 'searchcost') {
      if (`${value}`.length > 255) {
        tFormRef.current.okRef.show(
          {
            message: 'Harap memasukkan kata kunci kurang dari 255 karakter!',
          },
          () => {},
          () => {}
        )
        e.target.value = searchcost
        return setsearchcost(searchcost)
      }
      return setsearchcost(value)
    }
    onChangeData({[ariaLabel]: value})
  }
  const onBlur = e => {
    const isRequired = onRequired(e)
    if (isRequired) {
      return
    }
    const {ariaLabel, value} = e.target
    onChangeData({[ariaLabel]: value})
    if (ariaLabel === 'searchcost') {
      if (!pdata) {
        tFormRef.current.okRef.show(
          {
            message: 'Harap pilih pasien terlebih dahulu!',
          },
          () => {},
          () => {}
        )
        return
      }
      if (!isString(value) || value?.length === 0 || value?.length >= 255) {
        tFormRef.current.okRef.show(
          {
            message: 'Harap memasukkan kata kunci pencarian terlebih dahulu!',
          },
          () => {},
          () => {}
        )
        return
      }
      if (value === prevsearchcost) {
        return
      }
      setLoading(true)
      return onLoadCost(loadlimititems, 0, value)
    }
  }
  const onFocus = e => {
    const {ariaLabel, value} = e.target
    const lstreadonly = [
      `selected-pasien`,
      `cart-item-`,
      `date-visiting-pasien`,
      `num-visiting-pasien`,
      `sex-visiting-pasien`,
      `addr-visiting-pasien`,
    ]
    if (lstreadonly.findIndex(fn => `${ariaLabel}`.includes(fn)) >= 0) {
      return e.target.blur()
    }
    onChangeData({[ariaLabel]: value})
  }
  const onClick = e => {
    const {ariaLabel, previousSibling} = e.target
    // console.log('ariaLabel, previousSibling', ariaLabel, previousSibling)
    if (!loadinginitial) {
      if (ariaLabel === 'clearsearchcost') {
        setitems([])
        setprevsearchcost('')
        previousSibling.value = ''
        return setsearchcost('')
      }
      if (ariaLabel === 'srcvisit') {
        setsmodasearch(Date.now())
        return modalSearchRef.current.open(ariaLabel, {})
      }
    }
  }
  const onBackPress = e => {
    tFormRef.current.yesNoRef.show(
      {
        message: 'Apakah anda yakin akan keluar pembayaran!',
      },
      () => {
        if (window?.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage('onBackPress:')
        } else {
          navigate(-1)
        }
      },
      () => {}
    )
    // console.log('onBackPress', e)
  }

  const fetchCustomBpjsPcare = async () => {
    const uid = dctid
    await axios
      .post(
        `${BASE_URL}/backend-data-registrasi/get-custom`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // console.error('fetchCustomBpjsPcare', res?.data)
        // if (res?.data) {
        if (res?.data) {
          setCustomPcare(true)
        }
        // }
      })
      .catch(e => {
        console.error('Terjadi Kesalahan saat Cek Custom BPJS PCare', e)
      })
      .finally(() => {})
  }

  // Initial
  useEffect(() => {
    var errorInitial = false,
      errorMsg = ''
    if (data === null) {
      // Get User informations
      controllers(
        {
          key: 'user-info',
          dispatch: () => {},
          _ldata: 'mUserInfo',
          id: dctid,
        },
        navigate
      )
        .then(({data: res, message}) => {
          // console.log('res mUserInfo : ', res)
          if ([12, 10].includes(res?.status)) {
            fetchCustomBpjsPcare()
            setdata(res)
            if (kid) {
              onFetchVisit()
            }
          } else {
            errorInitial = true
            if (res?.status === 11) {
              errorMsg = `[AE11] An invalid user!`
            } else {
              errorMsg = `[AE1] An invalid status user!`
            }
          }
        })
        .catch(err => {
          errorInitial = true
          errorMsg = `[AE0] Terjadi Kesalahan!`
          // console.error('err mUserInfo : ', err)
        })
        .finally(() => {
          seterrorinitial(errorInitial)
          seterrormessage(errorMsg)
          setLoading(!true)
          if (loadinginitial === true) {
            setloadinginitial(false)
          }
        })
    }
  }, [data, dctid])
  const fieldCart = ['name', 'type', 'price', 'disc', 'value']
  const generateFieldTable = (
    key,
    label,
    style = {},
    sorter = false,
    filter = false
  ) => {
    return {
      key,
      label,
      _style: style,
      sorter,
      filter,
    }
  }
  const fields = [
    generateFieldTable('no', 'No', {width: '5%'}),
    generateFieldTable('name', 'Pemeriksaan', {width: '26%'}),
    generateFieldTable('price', 'Tarif', {width: '22%'}),
    generateFieldTable('disc', 'Diskon', {width: '15%'}),
    generateFieldTable('nominaldisc', 'Nominal Diskon', {width: '22%'}),
    generateFieldTable('opt', 'Opsi', {width: '15%'}),
  ]
  const addToCart = useCallback(
    (e, data) => {
      var newdata = data,
        incart = cart.findIndex(fn => fn.id === data.id)
      if (incart >= 0) {
        var newcart = cart
        newcart[incart]['value'] += 1
        setcart([...newcart])
      } else {
        newdata['value'] = 1
        setcart([...cart, newdata])
      }
    },
    [cart]
  )

  const addToCartPcare = useCallback(
    (e, data) => {
      var newdata = data,
        incart = cart.findIndex(fn => fn.id === data.id)
      if (incart >= 0) {
        var newcart = cart
        newcart[incart]['value'] += 1
        setcart([...newcart])
        // console.error('Updated cart:', newcart)
      } else {
        // newdata['value'] = 1
        // setcart([...cart, newdata])
        // console.error('Added to cart:', newdata)
        var nopcarekunjungan = pdata?.kunpcarekunjungan || kunpcarekunjungan
        
        if (nopcarekunjungan && data?.biakodebpjs) {
          addTindakanPcare(data, pdata, cart, newdata, nopcarekunjungan, tFormRef?.current)
        } else {
          newdata['value'] = 1
          setcart([...cart, newdata])
        }
      }
    },
    [cart, pdata, kunpcarekunjungan, tFormRef]
  )

  const addTindakanPcare = async (data, pdata, cart, newdata, nopcarekunjungan, tFormRef) => {
    if (nopcarekunjungan && data?.biakodebpjs) {
      setLoading(true)
      var uid = dctid
      let entriTindakan = {
        kdTindakanSK: data?.kdTindakan || 0,
        noKunjungan: pdata?.kunpcarekunjungan || nopcarekunjungan,
        kdTindakan: data?.biakodebpjs || '',
        biaya: data?.biatarif || 0,
        keterangan: data?.bianama || '',
        hasil: data?.value || 1,
      }

      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/tindakan-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'tindakan',
            method: data?.kdTindakan ? 'PUT' : 'POST',
            data: entriTindakan,
          })
        )
        .then(({data}) => {
          // localStorage.setItem('add-tindakan-pcare', JSON.stringify(data))
          if (
            data?.metaData?.message?.toLocaleLowerCase().includes('created') ||
            data?.metaData?.message?.toLocaleLowerCase().includes('ok')
          ) {
            // console.error('add-tindakan-pcare', data)

            let kdTindakanSK =
              data.response && data?.response?.field == 'kdTindakanSK'
                ? `${data?.response?.message}`
                : ''

            // console.error('kdTindakanSK', kdTindakanSK)

            // localStorage.setItem('kdTindakanSK', JSON.stringify(kdTindakanSK))
            newdata['value'] = 1
            newdata['kdtindakansk'] = kdTindakanSK
            setcart([...cart, newdata])

            setLoading(false)
            // alert('Data Entri Tindakan berhasil disimpan.')
            tFormRef?.okRef?.show(
              {
                title: 'Berhasil',
                message: 'Data Tindakan berhasil ditambahkan ke Pcare.',
                type: 'success',
              },
              () => {},
              () => {}
              // handlerOk,
              // handlerOk
            )
          } else {
            console.error('failed-tindakan-pcare', data)
            let countRes = data.response ? data.response.length : 1
            var message =
              data.response && data.response.length > 0
                ? `${data.response[0].field} ${data.response[0].message}`
                : ''
            let metadataMessage =
              data.metaData && data.metaData.message
                ? data.metaData.message
                : ''

            if (countRes > 1) {
              let mergedMessages = ''
              data.response.forEach(item => {
                mergedMessages += `${item.field} ${item.message}, `
              })
              message = mergedMessages.trim()
            }

            if (!message && metadataMessage) {
              message = metadataMessage
            }

            message = message
              ? message
              : `Gagal menambahkan Tindakan ke PCare dengan No. Kunjungan ${pdata?.kunpcarekunjungan || nopcarekunjungan}`

            newdata['value'] = 1
            setcart([...cart, newdata])
            setLoading(false)
            // alert(message)
            tFormRef?.okRef?.show(
              {
                message: message,
              },
              () => {},
              () => {}
            )
          }
        })
        .catch(e => {
          console.error('error-tindakan-pcare', e)
          newdata['value'] = 1
          setcart([...cart, newdata])
          setLoading(false)
          // alert('Gagal menambahkan Tindakan ke Pcare')
          tFormRef?.okRef?.show(
            {
              message: 'Gagal menambahkan Tindakan ke Pcare',
            },
            () => {},
            () => {}
          )
        })
    }
  }
  const deleteTindakanPcare = async (data, pdata, newcart, nopcarekunjungan, tFormRef) => {
    if (nopcarekunjungan && data?.kdtindakansk) {
      setLoading(true)
      var uid = dctid
      let deleteTindakan = {
        kdTindakanSK: data?.kdtindakansk || 0,
        noKunjungan: nopcarekunjungan || '',
        kdTindakan: data?.biakodebpjs || '',
      }

      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/tindakan-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'tindakan',
            method: 'DELETE',
            data: deleteTindakan,
          })
        )
        .then(({data: resdata = {}}) => {
          // console.error('delete-tindakan-pcare', resdata)
          if (resdata?.metaData?.message?.toLocaleLowerCase().includes('ok')) {
            setLoading(false)
            setcart([...newcart])
            tFormRef?.okRef?.show(
              {
                title: 'Berhasil',
                message: 'Tindakan berhasil dihapus dari PCare',
                type: 'success',
              },
              () => {},
              () => {}
            )
          } else {
            console.error('failed-delete-tindakan-pcare', resdata)
            let countRes = resdata?.response ? resdata?.response.length : 1
            var message =
              resdata?.response && resdata?.response.length > 0
                ? `${resdata?.response[0].field} ${resdata?.response[0].message}`
                : ''
            let metadataMessage =
              resdata?.metaData && resdata?.metaData.message
                ? resdata?.metaData.message
                : ''

            if (countRes > 1) {
              let mergedMessages = ''
              resdata?.response.forEach(item => {
                mergedMessages += `${item.field} ${item.message}, `
              })
              message = mergedMessages.trim()
            }

            if (!message && metadataMessage) {
              message = metadataMessage
            }

            message = message
              ? message
              : `Gagal menghapus Tindakan dari PCare dengan No. Kunjungan ${pdata?.kunpcarekunjungan}`

            setLoading(false)
            tFormRef?.okRef?.show(
              {
                message: message,
              },
              () => {},
              () => {}
            )
          }
        })
        .catch(e => {
          console.error('error-delete-tindakan-pcare', e)
          setLoading(false)
          tFormRef?.okRef?.show(
            {
              message: 'Gagal menghapus Tindakan dari Pcare',
            },
            () => {},
            () => {}
          )
        })
    }
  }
  const minToCart = useCallback(
    (e, data) => {
      var incart = cart.findIndex(fn => fn.id === data.id)
      if (incart >= 0) {
        var newcart = cart
        if (newcart[incart]['value'] - 1 === 0) {
          modalPaymentRef.current.open('delete', data)
          return true
        }
        newcart[incart]['value'] -= 1
        setcart([...newcart])
        return true
      }
    },
    [cart]
  )
  const delToCart = useCallback(
    (e, data) => {
      var incart = cart.findIndex(fn => fn.id === data.id)
      if (incart >= 0) {
        var newcart = cart.filter(fl => fl.id !== data.id)
        setcart([...newcart])
        return true
      }
    },
    [cart]
  )
  const delToCartPcare = useCallback(
    (e, data) => {
      // console.error('delToCartPcare: data', data)
      var incart = cart.findIndex(fn => fn.id === data.id)
      if (incart >= 0) {
        // console.error('delToCartPcare: pdata', pdata)
        // console.error('delToCartPcare: cart', cart)
        var newcart = cart.filter(fl => fl.id !== data.id)
        var nopcarekunjungan = pdata?.kunpcarekunjungan || kunpcarekunjungan
        if (nopcarekunjungan && data?.kdtindakansk) {
          deleteTindakanPcare(data, pdata, newcart, nopcarekunjungan, tFormRef?.current)
        } else {
          setcart([...newcart])
          return true
        }
      }
    },
    [cart, pdata, tFormRef]
  )
  const onPrcntDiscItem = (e, data) => {
    var limit = 0,
      newdisc = 0,
      newdisc2 = '',
      {ariaLabel, value, previousSibling, nextSibling, focus} = e.target
    var incart = cart.findIndex(fn => fn.id === data.id)
    newdisc = value
    limit = 5
    if (`${newdisc}`.includes('%')) {
      newdisc = newdisc.replace(/\%/g, '')
    }
    if (`${newdisc}`.includes(',')) {
      newdisc = newdisc.replace(/\,/g, '.')
      const spliter = newdisc.split(/\./g)
      if (parseInt(newdisc) < 100 && spliter[1].length > 2) {
        for (let i = 0; i < newdisc.length; i++) {
          if (newdisc[i] === '.') {
            newdisc2 += newdisc[i + 1]
            newdisc2 += newdisc[i]
            i += 1
          } else {
            newdisc2 += newdisc[i]
          }
        }
      } else {
        newdisc2 = newdisc
      }
    } else {
      newdisc2 = newdisc
    }
    newdisc2 = `${newdisc2}`.slice(0, limit)
    if (`${newdisc2}`.includes('-')) {
      newdisc2 = 0
    }
    if (newdisc2 > 100) {
      newdisc2 = 100
    }
    newdisc2 = parseFloat(newdisc2) || 0
    var newcart = cart
    newcart[incart]['disc'] = `${newdisc2}`
    newcart[incart]['nominaldisc'] = (newdisc2 * newcart[incart]['price']) / 100
    return newcart[incart]
  }
  const onNmnalDiscItem = (e, data) => {
    var limit = 0,
      newnominal = 0,
      newprice = parseInt(data.price),
      {ariaLabel, value, previousSibling, nextSibling, focus} = e.target
    var incart = cart.findIndex(fn => fn.id === data.id)
    limit = `${newprice}`.length
    newnominal = `${value}`
    newnominal = newnominal.includes('.')
      ? newnominal.replace(/\./g, '')
      : newnominal
    // newnominal = newnominal.slice(0, limit)
    if (newnominal > newprice) {
      newnominal = newprice
    }
    if (`${newnominal}`.includes('-')) {
      newnominal = 0
    }
    newnominal = parseInt(newnominal) || 0
    var newcart = cart
    newcart[incart]['disc'] = `${parseFloat((newnominal / newprice) * 100)}`
    newcart[incart]['nominaldisc'] = parseInt(newnominal)
    return newcart[incart]
  }
  const onSaveDiscItem = (e, data) => {
    var incart = cart.findIndex(fn => fn.id === data.id)
    var newcart = cart
    newcart[incart] = data
    setcart([...newcart])
  }
  const onSavePayment = (e, data, tFormRef) => {
    var error = false,
      errorMsg = 'Gagal menyimpan data!',
      total = 0,
      navid = '',
      jenis = ''
    const closemodalpayment = modalPaymentRef.current.close
    if (data.type === 1) {
      // Tunai
      jenis = 'tunai'
      total = data?.tunai || 0
    }
    if (data.type === 2) {
      // Piutang
      jenis = 'piutang'
      total = data?.dp || 0
    }
    if (data.type === 3) {
      // Kartu
      jenis = 'kartu'
      total = data?.kartucash || 0
      total += data?.kartu || 0
    }
    // Validasi pembayaran kartu
    if (data?.kartucash >= data.subcapt1 && data.type === 3) {
      tFormRef.okRef.show(
        {
          message: 'Nominal tunai terlalu besar untuk jenis pembayaran kartu!',
        },
        () => {},
        () => {}
      )
      return true
    }
    // Validasi pembayaran piutang
    if (total >= data.subcapt1 && data.type === 2) {
      tFormRef.okRef.show(
        {
          message:
            'Nominal pembayaran melebehi nominal pembelian, Untuk pembayaran jenis piutang!',
        },
        () => {},
        () => {}
      )
      return true
    }
    total -= data.subcapt1
    // Validasi pembayaran selain piutang dengan nominal yg kurang
    if (total < 0 && data.type !== 2) {
      tFormRef.okRef.show(
        {
          message: 'Nominal pembayaran kurang!',
        },
        () => {},
        () => {}
      )
      return true
    }
    let data_satusehat = {
      jenis,
      id: null,
      dctid: dctid,
      kid: data.pdata.id,
      pid: data.pdata.pid,
    }
    // Alert Konfirmasi
    tFormRef.yesNoRef.show(
      {
        message:
          'Pastikan data yg anda masukkan sudah benar, yakin ingin melanjutkan?',
      },
      () => {
        setLoading(true)
        closemodalpayment()
        return controllers(
          {
            key: 'insert-payment',
            dispatch: () => {},
            _ldata: 'mInsertPembayaran',
            ...data,
            tanggal: moment().format('YYYY-MM-DD HH:mm:ss'),
            jenis,
            dctid: dctid,
            kid: data.pdata.id,
            pid: data.pdata.pid,
          },
          navigate
        )
          .then(({status, data, message}) => {
            // console.log('res', status, message)
            if (!status || status !== 1) {
              error = true
              errorMsg = message || '[RIP0] Gagal menyimpan data!'
            } else if (status === 1) {
              data_satusehat['id'] = data.navid
              let params_satusehat = {
                data_satusehat: JSON.stringify(data_satusehat),
                userid: dctid,
                klid: dctid,
                appid: 'dk13',
                reg: 'dbdok',
                kunid: data_satusehat.kid,
              }
              triggerEventBus(
                'kunjungan',
                'satusehat-kunjungan/procedure',
                params_satusehat
              )
              errorMsg =
                'Data berhasil disimpan.\nApakah anda ingin mencetak struk?'
              navid = data.navid
            } else {
              error = true
              errorMsg = message || '[RIP1] Gagal menyimpan data!'
            }
          })
          .catch(err => {
            // console.error('err', err)
            error = true
            errorMsg = '[RIP2] Gagal menyimpan data!'
          })
          .finally(() => {
            setLoading(false)
            if (!error) {
              const formArray = Object.values(tFormRef.formRef)
              const isearchcost = formArray.find(
                fn => fn?.ariaLabel === 'searchcost'
              )
              if (!!isearchcost) {
                isearchcost.value = ''
              }
              setsearchcost('')
              setprevsearchcost('')
              setpdata(null)
              setitems([])
              setcart([])
              tFormRef.yesNoRef.show(
                {
                  title: 'Berhasil',
                  message: errorMsg,
                },
                () => {
                  // var navmode = mode === 'phone' ? '_self' : '_blank'
                  var navmode = '_self'
                  if (Number(kid) === Number(pdata?.id)) {
                    navigate(`/prd-tra-pembayaran/cetak/${navid}`, {
                      replace: false,
                    })
                  } else {
                    window.open(`/prd-tra-pembayaran/cetak/${navid}`, navmode)
                  }
                },
                () => {}
              )
            } else {
              tFormRef.okRef.show(
                {
                  message: errorMsg,
                },
                () => {},
                () => {}
              )
            }
          })
      },
      () => {}
    )
  }
  const grandtotalCalculation = cart => {
    var total = 0
    if (cart?.length > 0) {
      for (let i = 0; i < cart.length; i++) {
        var {price, nominaldisc, value} = cart[i]
        total += (price - nominaldisc) * value
      }
    }
    return total
  }
  useEffect(() => {
    if (headerRef.current) {
      setlayout({
        ...layout,
        header: {
          height: headerRef.current?.clientHeight || 0,
          width: headerRef.current?.clientWidth || 0,
        },
      })
    }
  }, [])

  const onClickCheckout = () => {
    var errorMsg = 'Silakan'
    if (!pdata) {
      errorMsg += ' memilih kunjungan pasien terlebih dahulu!'
      tFormRef.current.okRef.show(
        {
          message: errorMsg,
        },
        () => {},
        () => {}
      )
    } else if (!(cart?.length > 0)) {
      errorMsg = 'Anda belum memilih Biaya, ' + errorMsg
      errorMsg += ' pilih salah satu untuk melakukan pembayaran!'
      tFormRef.current.okRef.show(
        {
          message: errorMsg,
        },
        () => {},
        () => {}
      )
    } else {
      let defjatuhtempo = new Date()
      defjatuhtempo.setDate(defjatuhtempo.getDate() + 1)
      modalPaymentRef.current.open('checkout', {
        ...data,
        chart: cart,
        disc: 0,
        nominaldisc: 0,
        pdata,
        deadline: tglYMD(defjatuhtempo), // Kasih default value deadline agar tidak NaN kalau tgl deadlinenya gk diisi (dibiarkan default)
      })
    }
  }

  const grandtotal = useMemo(() => grandtotalCalculation(cart), [cart])

  const wrapmode = mode === 'phone' ? '' : 'flex-1'
  const wrapleft = classNames(wrapmode, 'flex flex-col items-start')
  const wrapright = classNames(wrapmode, 'flex')
  let priceSubTitile = toCurrency(grandtotal)
  let priceTitle = 'Total Biaya'
  let buttonTitle = 'Bayar'
  return (
    <Context.Provider value={{cart, addToCart, minToCart}}>
      {errorinitial ? (
        <FullErrorView message={errormessage} />
      ) : (
        <>
          <VmView className={'FormCashierWrapper'}>
            <TemplateForm
              ref={tFormRef}
              label={labelForm}
              loading={loading}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onChangeCapture={onChangeCapture}
              onScrollCapture={onScrollCapture}
              onClick={onClick}
              className={classNames('FormCashier', mode)}
            >
              {mode === 'phone' ? (
                <VmView className={classNames('VmLeftCashier', mode)}>
                  <VmView
                    key={`bg-${headerRef.current?.clientHeight}`}
                    style={
                      layout?.header
                        ? {height: (pdata?.number ? 260 : 188) - 6}
                        : {}
                    }
                    className={classNames('BgTopFormCashier', mode)}
                  >
                    <VmIcons
                      color="white"
                      variant="abstract"
                      name="VmWave0Icon"
                    />
                    <VmIcons
                      color="white"
                      variant="abstract"
                      name="VmWave1Icon"
                    />
                  </VmView>
                  <VmView ref={headerRef}>
                    <VmView className={classNames('head', mode)}>
                      <VmButton color="#FFF" onClick={onBackPress}>
                        <VmIcons
                          size={20}
                          color={rgbcolors.primary}
                          name="VmChevronLeftIcon"
                        />
                      </VmButton>
                      <VmView className={classNames('title', mode)}>
                        <VmText
                          data-placeholder={loadinginitial}
                          className={classNames('name', mode)}
                        >
                          {data?.name || '-'}
                        </VmText>
                        <VmText
                          data-placeholder={loadinginitial}
                          className={classNames('type', mode)}
                        >
                          {pageTitle || '-'}
                        </VmText>
                      </VmView>
                    </VmView>
                    <VmView className={classNames('info-user', mode)}>
                      <VmView className={classNames('wrap-input-user', mode)}>
                        <VmInputHandler
                          value={pdata?.patname || ''}
                          placeholder={'-'}
                          readOnly={true}
                          // readOnly={!!pdata?.patname || false}
                          outerprefix={() => <VmText>Pasien</VmText>}
                          label={'selected-pasien'}
                          prefix={() => (
                            <VmIcons
                              name="VmUserIcon"
                              variant="outline"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className={classNames('f0.3', mode)}>
                        <VmInputHandler
                          label="srcvisit"
                          typeInput={'button'}
                          value="Pilih Pasien"
                          suffix={() => <></>}
                          style={{
                            color: '#637CE3',
                            fontWeight: 700,
                          }}
                        />
                      </VmView>
                    </VmView>
                    {pdata?.number && (
                      <VmView className={classNames('info-user', mode)}>
                        <VmView className={classNames('wrap-input-user', mode)}>
                          <VmInputHandler
                            value={
                              pdata?.date
                                ? moment(pdata.date).format('DD MMM YYYY')
                                : '-'
                            }
                            readOnly={true}
                            label={'date-visiting-pasien'}
                            outerprefix={() => (
                              <VmText>Tanggal Kunjungan</VmText>
                            )}
                            prefix={() => (
                              <VmIcons
                                name="VmCalendarDaysIcon"
                                color={rgbcolors.primary}
                              />
                            )}
                            suffix={() => <></>}
                          />
                        </VmView>
                        <VmView className={classNames('wrap-input-user', mode)}>
                          <VmInputHandler
                            value={pdata?.number || '-'}
                            readOnly={true}
                            label={'num-visiting-pasien'}
                            outerprefix={() => <VmText>Nomor Kunjungan</VmText>}
                            prefix={() => (
                              <VmIcons
                                variant="outline"
                                name="VmTicketIcon"
                                color={rgbcolors.primary}
                              />
                            )}
                            suffix={() => <></>}
                          />
                        </VmView>
                        <VmView className={classNames('wrap-input-user', mode)}>
                          <VmInputHandler
                            value={pdata?.sex || '-'}
                            readOnly={true}
                            label={'sex-visiting-pasien'}
                            outerprefix={() => <VmText>Jenis Kelamin</VmText>}
                            prefix={() => (
                              <VmIcons
                                name="VmUserIcon"
                                variant="outline"
                                color={rgbcolors.primary}
                              />
                            )}
                            suffix={() => <></>}
                          />
                        </VmView>
                        <VmView className={classNames('wrap-input-user', mode)}>
                          <VmInputHandler
                            value={pdata?.address || '-'}
                            readOnly={true}
                            label={'addr-visiting-pasien'}
                            outerprefix={() => <VmText>Alamat</VmText>}
                            prefix={() => (
                              <VmIcons
                                name="VmMapPinIcon"
                                color={rgbcolors.primary}
                              />
                            )}
                            suffix={() => <></>}
                          />
                        </VmView>
                      </VmView>
                    )}
                    <VmView className={classNames('search-bar', mode)}>
                      <VmInputHandler
                        placeholder="Cari Pemeriksaan "
                        label="searchcost"
                        enterKeyHint="enter"
                        prefix={() => (
                          <VmIcons
                            name="VmMagnifyingGlassIcon"
                            color={rgbcolors.secondary}
                          />
                        )}
                        suffix={() =>
                          searchcost?.length > 0 ? (
                            <VmInput
                              {...{
                                style: {
                                  width: 'fit-content',
                                  color: 'var(--color-red4-payment)',
                                  background: '#00000000',
                                },
                                typeInput: 'button',
                                value: 'ⓧ',
                                label: 'clearsearchcost',
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </VmView>
                  </VmView>
                  {prevsearchcost?.length > 0 && (
                    <Fragment key={`wrap-f-${mode}-${prevsearchcost}`}>
                      <VmView
                        key={`PreViewSearch-${mode}-${prevsearchcost}`}
                        className="PreViewSearch"
                      >
                        <VmText> {' Hasil Pencarian '} </VmText>
                        <VmText label="actvsearch">
                          {' ' + prevsearchcost}
                        </VmText>
                      </VmView>
                      <VmDataTable
                        showOverTableActions={false}
                        activeActions={false}
                        activeNumbers={false}
                        id={'table-cost'}
                        key={`table-cost-${mode}`}
                        items={items}
                        fields={[generateFieldTable('no', '', {width: '100%'})]}
                        addTableClasses={'SearchPhone'}
                        scopedSlots={{
                          no: (im, index) => {
                            return (
                              <td>
                                <VmView
                                  key={`${mode}-${index}-${Date.now()}`}
                                  className="search-item-phone"
                                >
                                  <VmView>
                                    <VmView className="name-item">
                                      {im?.name || '-'}
                                    </VmView>
                                    <VmView className="price-item">
                                      {im?.price ? toCurrency(im.price) : '-'}
                                    </VmView>
                                    <VmView className="disc-item">
                                      Diskon{' '}
                                      {im?.disc
                                        ? toCurrency(
                                            im?.disc,
                                            im?.disc < 100 ? 2 : 0
                                          )
                                        : '0'}
                                      %
                                    </VmView>
                                    {/* <VmView className="nominal-disc-item">
                                {im?.nominaldisc ? toCurrency(im.nominaldisc) : '0'}
                              </VmView> */}
                                  </VmView>
                                  <VmView>
                                    <VmButton
                                      onClick={e =>
                                        customPcare
                                          ? addToCartPcare(e, im)
                                          : addToCart(e, im)
                                      }
                                      color="info"
                                      key={`item-opt-add-${index}`}
                                    >
                                      Tambah
                                    </VmButton>
                                  </VmView>
                                </VmView>
                              </td>
                            )
                          },
                          details: (im, index) => {
                            if (index === items.length - 1) {
                              return (
                                <VmView className="flex justify-center items-center mb-10">
                                  {onloadmoreitems ? (
                                    <VmSpinner
                                      style={{
                                        color: 'var(--color-blue0-payment)',
                                      }}
                                    />
                                  ) : (
                                    '-- Sudah tidak ada item --'
                                  )}
                                </VmView>
                              )
                            }
                            return <></>
                          },
                        }}
                      />
                    </Fragment>
                  )}
                  <VmWrapCart
                    mode={mode}
                    key={`cart-${mode}-${cart?.length}`}
                    priceSubTitile={toCurrency(grandtotal)}
                    visibleprops={{
                      header: !(prevsearchcost?.length > 0),
                      body: !(prevsearchcost?.length > 0),
                    }}
                    height={
                      prevsearchcost?.length > 0 ? 0 : '-webkit-fill-available'
                    }
                    onClickCheckout={onClickCheckout}
                  >
                    {cart?.length > 0 ? (
                      <>
                        {cart.map((im, index) => {
                          if (isString(im)) {
                            return <></>
                          }
                          const key = `cart-item-${mode}-${index}-${Date.now()}`
                          return (
                            <VmCartItem
                              key={key}
                              item={{
                                ...im,
                                disc: `${toCurrency(
                                  im.disc,
                                  im.disc < 100 && im.disc > 0.01 ? 2 : 0
                                )}%`,
                              }}
                              index={index}
                              label={key}
                              mode={mode}
                              fields={fieldCart}
                              onClick={(e, citem, type) => {
                                // console.log('citem', citem)
                                if (type === 'onClickChange') {
                                  modalPaymentRef.current.open('disc', citem)
                                }
                                if (type === 'onClickDelete') {
                                  modalPaymentRef.current.open('delete', citem)
                                }
                                if (type === 'onClickMinus') {
                                  minToCart(e, citem)
                                }
                                if (type === 'onClickPlus') {
                                  addToCart(e, citem)
                                }
                              }}
                            />
                          )
                        })}
                      </>
                    ) : (
                      emptycart
                    )}
                  </VmWrapCart>
                </VmView>
              ) : (
                <>
                  {/* Untuk Mode Web (Tablet) & Desktop */}
                  <VmWrapCart
                    mode={mode}
                    priceSubTitile={priceSubTitile}
                    onClickCheckout={onClickCheckout}
                  >
                    {cart?.length > 0
                      ? cart.map((im, index) => {
                          if (isString(im)) {
                            return <></>
                          }
                          const key = `cart-item-${mode}-${index}-${Date.now()}`
                          return (
                            <VmCartItem
                              key={key}
                              item={{
                                ...im,
                                disc: `${toCurrency(
                                  im.disc,
                                  im.disc < 100 && im.disc > 0.01 ? 2 : 0
                                )}%`,
                              }}
                              index={index}
                              label={key}
                              fields={fieldCart}
                              onClick={(e, citem, type) => {
                                // console.log('citem', citem)
                                if (type === 'onClickChange') {
                                  modalPaymentRef.current.open('disc', citem)
                                }
                                if (type === 'onClickDelete') {
                                  modalPaymentRef.current.open('delete', citem)
                                }
                                if (type === 'onClickMinus') {
                                  minToCart(e, citem)
                                }
                                if (type === 'onClickPlus') {
                                  addToCart(e, citem)
                                }
                              }}
                            />
                          )
                        })
                      : emptycart}
                  </VmWrapCart>
                  <VmView className="p-[10px]" />
                  <VmView className="VmLeftCashier">
                    <VmView className="BgTopFormCashier">
                      <VmIcons
                        color="white"
                        variant="abstract"
                        name="VmWave0Icon"
                      />
                      <VmIcons
                        color="white"
                        variant="abstract"
                        name="VmWave1Icon"
                      />
                    </VmView>

                    {/* Mode desktop tidak tampil header */}
                    {mode == 'web' && (
                      <VmView className="head">
                        <VmButton color="#FFF" onClick={onBackPress}>
                          <VmIcons
                            size={20}
                            color={rgbcolors.primary}
                            name="VmChevronLeftIcon"
                          />
                        </VmButton>
                        <VmView className="title">
                          <VmText
                            data-placeholder={loadinginitial}
                            className="name"
                          >
                            {data?.name || '-'}
                          </VmText>
                          <VmText
                            data-placeholder={loadinginitial}
                            className="type"
                          >
                            {pageTitle || '-'}
                          </VmText>
                        </VmView>
                      </VmView>
                    )}

                    <VmView className="info-user">
                      <VmView className="wrap-input-user !flex-initial">
                        <VmInputHandler
                          value={moment().format('DD MMM YYYY')}
                          readOnly={true}
                          outerprefix={() => <VmText>Tanggal Faktur</VmText>}
                          prefix={() => (
                            <VmIcons
                              name="VmCalendarDaysIcon"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                    </VmView>
                    <VmView className="info-user">
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={pdata?.patname || ''}
                          placeholder={'-'}
                          readOnly={true}
                          // readOnly={!!pdata?.patname || false}
                          outerprefix={() => <VmText>Pasien</VmText>}
                          prefix={() => (
                            <VmIcons
                              name="VmUserIcon"
                              variant="outline"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className="wrap-input-user-2">
                        <VmInputHandler
                          label="srcvisit"
                          typeInput={'button'}
                          value="Pilih Pasien"
                          suffix={() => <></>}
                          style={{color: '#637CE3', fontWeight: 700}}
                        />
                      </VmView>
                    </VmView>
                    <VmView className="info-user">
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={pdata?.address || '-'}
                          readOnly={true}
                          outerprefix={() => <VmText>Alamat</VmText>}
                          prefix={() => (
                            <VmIcons
                              name="VmMapPinIcon"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={pdata?.phone || '-'}
                          readOnly={true}
                          outerprefix={() => <VmText>No Telepon</VmText>}
                          prefix={() => (
                            <VmIcons
                              variant="solid"
                              name="VmPhoneIcon"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={pdata?.sex || '-'}
                          readOnly={true}
                          outerprefix={() => <VmText>Jenis Kelamin</VmText>}
                          prefix={() => (
                            <VmIcons
                              name="VmUserIcon"
                              variant="outline"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={
                            pdata?.date
                              ? moment(pdata.date).format('DD MMM YYYY')
                              : '-'
                          }
                          readOnly={true}
                          outerprefix={() => <VmText>Tanggal Kunjungan</VmText>}
                          prefix={() => (
                            <VmIcons
                              name="VmCalendarDaysIcon"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                      <VmView className="wrap-input-user">
                        <VmInputHandler
                          value={pdata?.number || '-'}
                          readOnly={true}
                          outerprefix={() => <VmText>Nomor Kunjungan</VmText>}
                          prefix={() => (
                            <VmIcons
                              variant="outline"
                              name="VmTicketIcon"
                              color={rgbcolors.primary}
                            />
                          )}
                          suffix={() => <></>}
                        />
                      </VmView>
                    </VmView>
                    <VmView className="search-bar">
                      <VmInputHandler
                        placeholder="Cari Biaya Pemeriksaan . . . "
                        label="searchcost"
                        enterKeyHint="enter"
                        prefix={() => (
                          <VmIcons
                            name="VmMagnifyingGlassIcon"
                            color={rgbcolors.secondary}
                          />
                        )}
                        suffix={() =>
                          searchcost?.length > 0 ? (
                            <VmInput
                              {...{
                                style: {
                                  width: 'fit-content',
                                  color: 'var(--color-red4-payment)',
                                  background: '#00000000',
                                },
                                typeInput: 'button',
                                value: 'ⓧ',
                                label: 'clearsearchcost',
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </VmView>
                    <VmView className="table-search">
                      {/* Dibedakan untuk mode web (Tablet) dan desktop (Desktop Web)  */}
                      {mode == 'desktop' && (
                        <Fragment>
                          <VmView className="grid grid-cols-2 gap-x-8 gap-y-2">
                            {items.map((item, i) => {
                              let {bianama, biatarif, biadiskon} = item
                              return (
                                <VmView
                                  key={i}
                                  className="flex flex-row items-center"
                                >
                                  <VmView className="flex-1">
                                    <VmText className="text-sm">
                                      {bianama}
                                    </VmText>
                                    <VmText className="text-sm font-bold">
                                      {toCurrency(biatarif)}
                                    </VmText>
                                    <VmText className="text-sm text-red-500">
                                      Diskon {biadiskon}%
                                    </VmText>
                                  </VmView>
                                  <VmButton
                                    onClick={e =>
                                      customPcare
                                        ? addToCartPcare(e, item)
                                        : addToCart(e, item)
                                    }
                                    className={
                                      'py-2 px-8 !bg-[#0084CF] text-xs'
                                    }
                                  >
                                    Tambah
                                  </VmButton>
                                </VmView>
                              )
                            })}
                          </VmView>
                        </Fragment>
                      )}

                      {mode == 'web' && (
                        <VmDataTable
                          showOverTableActions={false}
                          activeActions={false}
                          activeNumbers={false}
                          id={'table-cost'}
                          key={`table-cost-${mode}`}
                          items={items}
                          fields={fields}
                          scopedSlots={{
                            no: (im, index) => {
                              return <td>{index + 1}</td>
                            },
                            name: (im, index) => {
                              return (
                                <td className="name-item">{im?.name || '-'}</td>
                              )
                            },
                            price: (im, index) => {
                              return (
                                <td className="price-item">
                                  {im?.price ? toCurrency(im.price) : '-'}
                                </td>
                              )
                            },
                            disc: (im, index) => {
                              return (
                                <td
                                  key={`${mode}-${index}-${Date.now()}`}
                                  className="disc-item"
                                >
                                  {im?.disc
                                    ? toCurrency(
                                        im?.disc,
                                        im?.disc < 100 && im.disc > 0.01 ? 2 : 0
                                      )
                                    : '0'}
                                  %
                                </td>
                              )
                            },
                            nominaldisc: (im, index) => {
                              return (
                                <td className="nominal-disc-item">
                                  {im?.nominaldisc
                                    ? toCurrency(im.nominaldisc)
                                    : '0'}
                                </td>
                              )
                            },
                            opt: (im, index) => {
                              return (
                                <td>
                                  <VmButton
                                    onClick={e =>
                                      customPcare
                                        ? addToCartPcare(e, im)
                                        : addToCart(e, im)
                                    }
                                    color="info"
                                    key={`item-opt-add-${index}`}
                                  >
                                    Tambah
                                  </VmButton>
                                </td>
                              )
                            },
                            details: (im, index) => {
                              if (index === items.length - 1) {
                                return (
                                  <>
                                    {onloadmoreitems ? (
                                      <VmSpinner
                                        style={{
                                          color: 'var(--color-blue0-payment)',
                                        }}
                                      />
                                    ) : (
                                      '-- Sudah tidak ada item --'
                                    )}
                                  </>
                                )
                              }
                              return <></>
                            },
                          }}
                        />
                      )}
                    </VmView>
                  </VmView>
                </>
              )}
            </TemplateForm>

            {/* Untuk mode desktop, Total Biaya Tampilkan di depan */}
            {['desktop', 'phone'].includes(mode) && (
              <VmView className="VmWrapCart !h-auto !rounded-t-xl shadow-lg !bg-transparent">
                <VmView className={classNames('footer !py-6', mode)}>
                  <VmView className={wrapleft}>
                    <VmText className={classNames('totalTitle', mode)}>
                      {priceTitle}
                    </VmText>
                    <VmText className={classNames('total !text-base', mode)}>
                      {priceSubTitile}
                    </VmText>
                  </VmView>
                  <VmView className={classNames(wrapright, '!flex-initial')}>
                    <VmButton
                      onClick={onClickCheckout}
                      full
                      className={classNames('bayar !px-16 !text-sm', mode)}
                    >
                      {buttonTitle}
                    </VmButton>
                  </VmView>
                </VmView>
              </VmView>
            )}
            {/* <p>Test Satu Dua</p> */}

            <ModalPembayaran
              ref={modalPaymentRef}
              onPrcntDiscItem={(e, data) =>
                onPrcntDiscItem(e, data, tFormRef.current)
              }
              onNmnalDiscItem={(e, data) =>
                onNmnalDiscItem(e, data, tFormRef.current)
              }
              onSaveDiscItem={(e, data) =>
                onSaveDiscItem(e, data, tFormRef.current)
              }
              onSavePayment={(e, data) =>
                onSavePayment(e, data, tFormRef.current)
              }
              delToCart={customPcare ? delToCartPcare : delToCart}
              setcart={setcart}
              cart={cart}
            />
            <ModalPencarian
              dms={`${smodasearch}`}
              ref={modalSearchRef}
              controllers={controllers}
              pathname={pathname}
              navigate={navigate}
              queryParams={params}
              onItemModalSearchClick={data =>
                onItemModalSearchClick(data, tFormRef.current)
              }
              dctid={dctid}
            />
          </VmView>
        </>
      )}
    </Context.Provider>
  )
}

export default FormPembayaran
